import React, { useState, useEffect } from 'react'

const useNavigatorOnline = () => {
  const supported =
    typeof window !== "undefined" &&
    typeof navigator !== "undefined" &&
    "onLine" in navigator;

  const [isOnline, setIsOnline] = useState({
    previous: supported ? navigator.onLine : false,
    current: supported ? navigator.onLine : false,
  });

  useEffect(() => {
    if(!supported) { return () => {}; }

    const updateStatus = (event) => {
      setIsOnline({
        previous: isOnline.current,
        current: event.type === "online",
      });
    };

    window.addEventListener("offline", updateStatus);
    window.addEventListener("online", updateStatus);

    return () => {
      window.removeEventListener("offline", updateStatus);
      window.removeEventListener("online", updateStatus);
    };
  }, [isOnline, supported]);

  return {
    backOnline: !isOnline.previous && isOnline.current,
    backOffline: isOnline.previous && !isOnline.current,
    isOnline: isOnline.current,
    isOffline: !isOnline.current,
  };
}

const ConnectionMonitor = () => {
  const status = useNavigatorOnline()

  return status.isOffline && (
    <div className="absolute bottom-0 left-0 m-5">
      <div className="flex items-center bg-red-500 py-2 px-3 shadow-md mb-2" >
        <div className="text-white max-w-xs ">
          Not currently connected to the internet
        </div>
      </div>
    </div>
  )
}

export default ConnectionMonitor
