import React from "react";
import pick from "lodash/pick";
import PropTypes from "prop-types";
import { Formik } from "formik";
import SecondaryButton from "../../../../SecondaryButton";
import SaveButton from "../../../../form/SaveButton";
import { neuroConsciousnessFields } from "../../../../../utils/assessmentUtils";
import { validationSchema } from "./neuroAssessmentValidations";
import {CONTINUE, EXIT,} from '../../../../../constants'
import FormCheckbox from "../../../../form/FormCheckbox";
import Error from "../../../../form/Error";

class NeuroAssessmentForm extends React.Component {
  constructor(props) {
    super(props);

    this.initialValues = {
      consciousness: {
        ...pick(props.assessment, neuroConsciousnessFields),
      },
    };

    this.consciousnessFields = neuroConsciousnessFields;

    this.formRef = React.createRef();
  }

  _handleSubmit = (values) => {
    this.props.onSubmit({
      id: this.props.assessment.id,
      ...values.consciousness,
    });

    if (this.state.submitType === CONTINUE) {
      this.props.onContinue();
    } else {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={this.initialValues}
        onSubmit={this._handleSubmit}
      >
        {({
          values,
          errors,
          handleSubmit,
          handleChange,
          setFieldValue,
          submitForm,
          isSubmitting,
          isValidating,
          resetForm,
        }) => {
          if (Object.keys(errors).length > 0 && isSubmitting && !isValidating) {
            this.formRef.current.scrollIntoView({ behavior: "smooth" });
          }
          return (
            <form onSubmit={handleSubmit} ref={this.formRef} id="neuro-assessment-form">
              <div className="p-6">

                <div className="my-6">
                  <h4 className="block text-gray-800 text-xl font-bold mb-6">Levels of Consciousness (Required)</h4>
                  {errors.consciousness && <Error id={`main-error-consciousness`}>{errors.consciousness}</Error>}
                  <div className="flex flex-wrap" id={`consciousness-checkboxes`}>
                    <FormCheckbox
                      className="mr-4 p-2 my-4 rounded flex items-center"
                      onClick={() => {
                        setFieldValue(`consciousness.consciousness_alert`, !values.consciousness.consciousness_alert)
                        if(values.consciousness.consciousness_lethargic){
                          setFieldValue(`consciousness.consciousness_lethargic`, false)
                        }
                      }}
                      disabled={values.consciousness.consciousness_non_responsive}
                      checked={values.consciousness.consciousness_alert && !values.consciousness.consciousness_non_responsive}>
                      Alert
                    </FormCheckbox>

                    <FormCheckbox
                      className="mr-4 p-2 rounded flex items-center"
                      onClick={() => {
                        setFieldValue(`consciousness.consciousness_lethargic`, !values.consciousness.consciousness_lethargic)
                        if(values.consciousness.consciousness_alert){
                          setFieldValue(`consciousness.consciousness_alert`, false)
                        }
                      }}
                      disabled={values.consciousness.consciousness_non_responsive}
                      checked={values.consciousness.consciousness_lethargic && !values.consciousness.consciousness_non_responsive}
                      id="neuro-consciousness-lethargic"
                    >
                      Lethargic
                    </FormCheckbox>

                    <FormCheckbox
                      className="mr-4 p-2 rounded flex items-center"
                      onClick={() => {
                        setFieldValue(`consciousness.consciousness_oriented`, !values.consciousness.consciousness_oriented)
                        if(values.consciousness.consciousness_disoriented){
                          setFieldValue(`consciousness.consciousness_disoriented`, false)
                        }
                      }}
                      disabled={values.consciousness.consciousness_non_responsive}
                      checked={values.consciousness.consciousness_oriented && !values.consciousness.consciousness_non_responsive}>
                      Oriented
                    </FormCheckbox>
                
                    <FormCheckbox
                      className="mr-4 p-2 rounded flex items-center"
                      onClick={() => {
                        setFieldValue(`consciousness.consciousness_disoriented`, !values.consciousness.consciousness_disoriented)
                        if(values.consciousness.consciousness_oriented){
                          setFieldValue(`consciousness.consciousness_oriented`, false)
                        }
                      }}
                      disabled={values.consciousness.consciousness_non_responsive}
                      checked={values.consciousness.consciousness_disoriented && !values.consciousness.consciousness_non_responsive}>
                      Disoriented
                    </FormCheckbox>

                    <FormCheckbox
                      className="mr-4 p-2 rounded flex items-center"
                      onClick={() => setFieldValue('consciousness.consciousness_forgetful', !values.consciousness.consciousness_forgetful)}
                      checked={values.consciousness.consciousness_forgetful && !values.consciousness.consciousness_non_responsive}
                      disabled={values.consciousness.consciousness_non_responsive}
                      id="consciousness-forgetful">
                      Forgetful
                    </FormCheckbox>
                    <FormCheckbox
                      className="mr-4 p-2 rounded flex items-center"
                      onClick={() => setFieldValue('consciousness.consciousness_short_term_memory_loss', !values.consciousness.consciousness_short_term_memory_loss)}
                      checked={values.consciousness.consciousness_short_term_memory_loss && !values.consciousness.consciousness_non_responsive}
                      disabled={values.consciousness.consciousness_non_responsive}
                      id="consciousness-short_term_memory_loss">
                    Short-term memory loss
                    </FormCheckbox>
                    <FormCheckbox
                      className="mr-4 p-2 rounded flex items-center"
                      onClick={() => setFieldValue('consciousness.consciousness_follows_simple_commands', !values.consciousness.consciousness_follows_simple_commands)}
                      checked={values.consciousness.consciousness_follows_simple_commands && !values.consciousness.consciousness_non_responsive}
                      disabled={values.consciousness.consciousness_non_responsive}
                      id="consciousness-follows_simple_commands">
                      Follows Simple Commands
                    </FormCheckbox>
                    <FormCheckbox
                      className="mr-4 p-2 rounded flex items-center"
                      onClick={() => {
                        resetForm();
                        setFieldValue(`consciousness.consciousness_alert`, false)
                        setFieldValue(`consciousness.consciousness_lethargic`, false)
                        setFieldValue(`consciousness.consciousness_oriented`, false)
                        setFieldValue(`consciousness.consciousness_disoriented`, false)
                        setFieldValue('consciousness.consciousness_forgetful', false)
                        setFieldValue('consciousness.consciousness_short_term_memory_loss', false)
                        setFieldValue('consciousness.consciousness_follows_simple_commands', false)
                        setFieldValue('consciousness.consciousness_non_responsive', !values.consciousness.consciousness_non_responsive)
                      }}
                      checked={values.consciousness.consciousness_non_responsive}
                      id="consciousness-non_responsive">
                      Non-Responsive
                    </FormCheckbox>
                  </div>
                </div>
                <hr />
              </div>

              <div className="p-4 px-8 text-right bg-gray-100 rounded-b-lg">
                <SecondaryButton
                  id="cancel-button"
                  className="inline-block mr-4"
                  type="button"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </SecondaryButton>
                <SaveButton
                  id="save-exit"
                  className="mr-4"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ submitType: EXIT }, () =>
                      submitForm()
                    );
                  }}
                >
                  Save and Exit
                </SaveButton>
                <SaveButton
                  id="save-continue"
                  className="mr-4"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ submitType: CONTINUE }, () =>
                      submitForm()
                    );
                  }}
                >
                  Save and Continue
                </SaveButton>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

NeuroAssessmentForm.propTypes = {
  assessment: PropTypes.object,
  onSubmit: PropTypes.func,
  onContinue: PropTypes.func,
  onCancel: PropTypes.func,
};

export default NeuroAssessmentForm;
