import React from 'react';
import { Provider } from 'react-redux';
import './index.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import Router from './router';
import store from './store';
import ConnectionMonitor from './components/ConnectionMonitor'

function App() {
  return (
    <Provider store={store}>
      <ConnectionMonitor />
      <Router />
    </Provider>
  );
}

export default App;
