import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { getClient, getUser } from "../../../../selectors";
import { getClinicalContinuationNotes } from "../../../../selectors/clinicalContinuationNotes";
import Component from "../../../../components/shiftReports/clinical/v1/ShiftReport";
import ShiftReportEndTime from "../../../../components/ShiftReportEndTime";
import ShiftReportModal from '../../../../components/shiftReports/modals/ShiftReportModal';
import { generateTimeRange, generateNoteTimeRange } from "../../../../utils/timeUtils";
import {
  assessmentComplete,
  extractBasicAssessment,
  extractDigestiveAssessment,
  extractCardioAssessment,
  extractNeuroAssessment,
  extractPulmonaryAssessment
} from "../../../../utils/assessmentUtils";
import {
  submitClinicalShiftReport,
  updateShiftReport,
  captureShiftReportImage
} from "../../../../actions";

class ShiftReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditingEnd: false,
      isConfirming: false,
      validationErrors: [],
      verificationImage: null
    };
  }

  componentDidUpdate = prevProps => {
    if(prevProps.shiftReport.verificationImage !== this.props.shiftReport.verificationImage) {
      this._handleSubmit()
    }

    if(prevProps.shiftReport.recordedAt !== this.props.shiftReport.recordedAt)  {
      this.props.submitShiftReport({
        shiftReport: this.props.shiftReport,
        painAssessments: this.props.painAssessments,
        assessment: this.props.assessment,
        intake: this.props.intake,
        output: this.props.output,
        woundCares: this.props.woundCares,
        vitals: this.props.vitals,
        clinicalContinuationNotes: this.props.clinicalContinuationNotes,
        verificationImage: this.props.verificationImage
      });
      this.props.history.push('/client');
    }
  }

  _captureImage = (image) => {
    console.log("Image Size (kb): ", (new TextEncoder().encode(image)).length / 1024)
    this.props.captureShiftReportImage(this.props.shiftReport.id, image.split(',')[1])
  }

  _validate = () => {
    let errors = []
    errors = this._validateVitals(errors)
    errors = this._validateAssessments(errors)
    errors = this._validateClinicalNotes(errors)
    return errors
  }

  _validateVitals = (errors) => {
    if(this.props.vitals.length < 1) {
      errors.push('Make sure you have recorded all required vitals.');
    }
    return errors
  }

  _validateClinicalNotes = (errors) => {
    const noteTimes = generateNoteTimeRange(
      this.props.shiftReport.start,
      this.props.shiftReport.end
    )

    if(this.props.clinicalContinuationNotes.length !== noteTimes.length) {
      errors.push('Make sure you have completed all required nursing notes');
    }
    return errors
  }

  _validateAssessments = (errors) => {
    const assessment = this.props.assessment
    const basicComplete = assessmentComplete(extractBasicAssessment(assessment))
    const digestiveComplete = assessmentComplete(extractDigestiveAssessment(assessment))
    const cardioComplete = assessmentComplete(extractCardioAssessment(assessment))
    const neuroComplete = assessmentComplete(extractNeuroAssessment(assessment))
    const pulmonaryComplete = assessmentComplete(extractPulmonaryAssessment(assessment))

    if(!basicComplete) {
      errors.push('Please complete the Basic assessment')
    }

    if(!digestiveComplete) {
      errors.push('Please complete the Digestive assessment')
    }

    if(!cardioComplete) {
      errors.push('Please complete the Cardiovascular assessment')
    }

    if(!neuroComplete) {
      errors.push('Please complete the Neurological assessment')
    }

    if(!pulmonaryComplete) {
      errors.push('Please complete the Pulmonary assessment')
    }

    return errors
  }

  _toggleConfirmation = () => {
    this.setState({
      isConfirming: !this.state.isConfirming
    })
  }

  _handleConfirmation = () => {
    this.setState({
      validationErrors: this._validate(),
      isConfirming: true
    })
  }

  _handleEndTimeSubmit = (endTime) => {
    this.props.updateShiftReport({
      ...this.props.shiftReport,
      ...endTime,
    });
    this.setState({ isEditingEnd: false });
  };

  _handleSubmit = () => {
    this.props.updateShiftReport({
      ...this.props.shiftReport,
      recordedAt: moment().format()
    })
  }

  render = () => (
    <React.Fragment>
      <ShiftReportEndTime
        start={this.props.shiftReport.start}
        end={this.props.shiftReport.end}
        date={moment(this.props.shiftReport.start)}
        onSubmit={this._handleEndTimeSubmit}
        onBackClick={() => this.setState({ isEditingEnd: false })}
        isOpen={this.state.isEditingEnd}
      />
      <ShiftReportModal
        errors={this.state.validationErrors}
        user={this.props.user}
        shiftEndTime={this.props.shiftReport.end}
        onSubmit={this._handleSubmit}
        isOpen={this.state.isConfirming}
        onCancel={this._toggleConfirmation}
        onCapture={this._captureImage}
      />

      <Component
        {...this.props}
        times={generateTimeRange(
          this.props.shiftReport.start,
          this.props.shiftReport.end
        )}
        onEditEndTime={() => this.setState({ isEditingEnd: true })}
        onSubmit={this._handleConfirmation}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateShiftReport: (shiftReport) => dispatch(updateShiftReport(shiftReport)),
  captureShiftReportImage: (shiftReportId, image) => dispatch(captureShiftReportImage(shiftReportId, image)),
  submitShiftReport: (shiftReportObj) => dispatch(submitClinicalShiftReport(shiftReportObj))
});

const mapStateToProps = (state, props) => {
  const shiftReport = state.entities.shiftReports.byId[props.match.params.id]

  return {
    user: getUser(state),
    client: getClient(state),
    shiftReport: shiftReport,
    assessment: shiftReport.assessment
      ? state.entities.assessments.byId[shiftReport.assessment]
      : null,
    intake: shiftReport.intake
      ? state.entities.intakes.byId[shiftReport.intake]
      : null,
    output: shiftReport.output
      ? state.entities.outputs.byId[shiftReport.output]
      : null,
    verificationImage: shiftReport.verificationImage
      ? state.entities.verificationImages.byId[shiftReport.verificationImage]
      : null,
    painAssessments: shiftReport.painAssessments.map(id => state.entities.painAssessments.byId[id]),
    woundCares: shiftReport.woundCares.map(id => state.entities.woundCares.byId[id]),
    clinicalContinuationNotes: getClinicalContinuationNotes(state, { shiftReport }),
    vitals: shiftReport.vitals.map(id => state.entities.vitals.byId[id])
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(ShiftReport);

export default Container;
