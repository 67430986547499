import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom'
import Header from '../../../Header';
import {
    pcaPersonalCareTasks,
    pcaPersonalCareTaskLabels,
    homeMakingTasks,
    homeMakingTaskLabels,
} from '../../../../constants';
import Container from '../../../layout/Container';
import IncidentReportTable from '../../../../components/IncidentReportTable'
import ShiftReportHeader from '../../../ShiftReportHeader';
import PersonalCareTaskWidget from '../../../PersonalCareTaskWidget';

const ShiftReportReadOnly = (props) => (
    <Container>
        <Header client={props.client} user={props.user} />
        <div className='p-8'>
            <ShiftReportHeader
                readOnly
                shiftReport={props.shiftReport}
                author={props.shiftReportUser}
            >
                <Link
                    to='/client'
                    className='cursor-pointer bg-blue-600 p-3 px-6 text-white font-semibold rounded-full'
                >
                    <i className='fas fa-arrow-circle-left inline-block mr-2' />{' '}
                    Return to Dashboard
                </Link>
            </ShiftReportHeader>
            <h1 className='text-2xl mb-4'>Personal Care Tasks</h1>
            <table className='w-full mb-8 table--rounded shadow-md rounded-lg'>
                <thead>
                    <tr className='bg-gray-100'>
                        <th></th>
                        {props.times.map((time) => (
                            <th className='pr-4 pb-4 pt-4' key={time}>
                                {moment(time).format('H:mm')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {pcaPersonalCareTasks.map((type, i) => (
                        <tr
                            className={i % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
                            key={i}
                        >
                            <td className='p-4 leading-tight w-64'>
                                <span className='block'>
                                    {pcaPersonalCareTaskLabels[type]}
                                </span>
                            </td>
                            {props.times.map((time) => (
                                <td
                                    className='pr-4 text-center py-2'
                                    key={time}
                                >
                                    <PersonalCareTaskWidget
                                        type={type}
                                        recordedAt={time}
                                        disabled={true}
                                        personalCareTask={
                                            props.pcaPersonalCareTasks[type] &&
                                            props.pcaPersonalCareTasks[type][time]
                                                ? props.pcaPersonalCareTasks[type][
                                                      time
                                                  ]
                                                : null
                                        }
                                        shiftReportId={props.shiftReport.id}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            <h1 className='text-2xl mb-4'>HomeMaking Tasks</h1>
            <table className='w-full mb-8 table--rounded shadow-md rounded-lg'>
                <thead>
                    <tr className='bg-gray-100'>
                        <th></th>
                        {props.times.map((time) => (
                            <th className='pr-4 pb-4 pt-4' key={time}>
                                {moment(time).format('H:mm')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {homeMakingTasks.map((type, i) => (
                        <tr
                            className={i % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
                            key={i}
                        >
                            <td className='p-4 leading-tight w-64'>
                                <span className='block'>
                                    {homeMakingTaskLabels[type]}
                                </span>
                            </td>
                            {props.times.map((time) => (
                                <td
                                    className='pr-4 text-center py-2'
                                    key={time}
                                >
                                    <PersonalCareTaskWidget
                                        type={type}
                                        recordedAt={time}
                                        disabled={true}
                                        personalCareTask={
                                            props.homeMakingTasks[type] &&
                                            props.homeMakingTasks[type][time]
                                                ? props.homeMakingTasks[type][
                                                      time
                                                  ]
                                                : null
                                        }
                                        shiftReportId={props.shiftReport.id}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            <h1 className='text-2xl mb-4'>Field Notes</h1>
            <div className='mb-8 flex flex-wrap'>
                <IncidentReportTable
                    incidentReports={props.incidentReports}
                    readOnly
                />
            </div>
        </div>
    </Container>
);

export default ShiftReportReadOnly;
