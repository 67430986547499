import React from 'react';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'animate.css/animate.min.css';
import Error from './form/Error';
import PrimaryButton from './form/PrimaryButton';
import CenterContainer from './layout/CenterContainer';

const Home = props => (
  <CenterContainer>
    <Formik
      validationSchema={Yup.object().shape({
        selected: Yup.object()
          .required('Please select your name and role first.')
          .nullable()
      })}
      initialValues={{ selected: null }}
      onSubmit={values => {
        props.onSubmit(values.selected.value);
      }}
    >
      {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
        }) => (
        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg w-1/2">
          <div className="p-8">
            <span id="login-dialog-heading" className="font-bold text-lg block mb-4">Select your name and role below...</span>
            <Select
              id="name"
              name="name"
              options={props.options}
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder="Select your name and role..."
              value={values.selected}
              onChange={selected => setFieldValue('selected', selected)}
            />
            {errors.selected && (<Error animate>{errors.selected}</Error>)}
          <div id="refresh-users" className="text-gray-600 text-sm pt-1">
            {!props.isRefreshed ? (
              <>
                <i className="fas fa-info-circle text-blue-600"></i> Don't see your name or role? <span id="refresh-users-span" onClick={props.refreshUsers} className="text-blue-600 cursor-pointer p-0" >  Click here to refresh the list.</span>
              </> 
              ) : 
              "Still don't see your name and role? Contact your case manager."
            }
          </div>
          </div>
          <div className="text-right p-4 px-8 bg-gray-100 rounded-b-lg">
            <PrimaryButton id="login-submit-button" type="submit"><i className="fas fa-sign-in mr-1 inline-block" /> Sign In</PrimaryButton>
          </div>
        </form>
      )}
    </Formik>
  </CenterContainer>
);

export default Home;
