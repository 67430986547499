import React, { useMemo } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { generateNoteTimeRange } from '../../../../utils/timeUtils'

const AssessmentCheck = ({active}) => (
  <i className={active ?
                'fas fa-check-circle text-3xl block mr-2 text-green-600' :
                'fas fa-check-circle text-3xl block mr-2 text-gray-400'
               } />
)

const AssessmentTableV2 = ({
  assessments,
  shiftReport,
  clinicalContinuationNotes,
  vitalsCount,
  woundCaresCount,
  painAssessmentsCount,
  intake,
  output
}) => {
  const times = useMemo(() => {
    return generateNoteTimeRange(
      shiftReport.start,
      shiftReport.end
    );
  }, [shiftReport]);

  const renderChecks = (notes) => {
    return times.map(time => {
      const formattedTime = moment(time).format()
      const note = notes.find(obj => obj.recordedAt === formattedTime);

      return <AssessmentCheck key={formattedTime} active={!!note} />
    })
  }

  return (
    <div className="bg-white justify-between rounded-lg shadow-md w-full">
      <div className="flex flex-row justify-between p-4">

        <div className="border-r border-gray-300 flex-1">
          <h4 className="text-gray-600 font-bold">Initial Assessment</h4>
          <div className="flex flex-row flex-wrap py-4">
            { assessments.map(assessment => (
              <div
                key={assessment.name}
                id={`assessment-${assessment.name}-checkbox`}
                className="flex flex-row justify-center items-center mr-16 mb-8">
                <AssessmentCheck active={assessment.active} />
                <div><Link to={assessment.url} id={`assessment-${assessment.name}`} className="text-blue-600">{assessment.name}</Link></div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex-1 pl-12">
          <div className="flex flex-row justify-between">
            <div>
              <h5><HashLink to="#notes-header" className="text-blue-600">Nursing Notes</HashLink></h5>

              <div className="flex flex-row justify-around mt-2">
                { renderChecks(clinicalContinuationNotes) }
              </div>
            </div>
            <div>
              <h5><HashLink to="#vitals-header" className="text-blue-600">Vitals</HashLink></h5>
              <h6 className="text-center text-3xl" >{vitalsCount}</h6>
            </div>
            <div>
              <h5><HashLink to="#pain-assessments-header" className="text-blue-600">Pain Assessments</HashLink></h5>
              <h6 className="text-center text-3xl" >{painAssessmentsCount}</h6>
            </div>
            <div>
              <h5><HashLink to="#wound-cares-header" className="text-blue-600">Wound Assessments</HashLink></h5>
              <h6 className="text-center text-3xl" >{woundCaresCount}</h6>
            </div>
          </div>
          <div className="flex flex-row mt-4">
            <div className="flex-1 mr-8 text-gray-600">
              <h6 className="mb-2"><HashLink to="#intake-header" className="text-blue-600">Intakes</HashLink></h6>
              <div className="flex flex-row justify-between" >
                <div >Breakfast</div>
                <div >{intake.breakfast}%</div>
              </div>
              <div className="flex flex-row justify-between" >
                <div>Lunch</div>
                <div>{intake.lunch}%</div>
              </div>
              <div className="flex flex-row justify-between" >
                <div>Dinner</div>
                <div>{intake.dinner}%</div>
              </div>
              <div className="flex flex-row justify-between" >
                <div>Snacks</div>
                <div>{[intake.snackOne, intake.snackTwo, intake.snackThree].filter(x => x).join(', ')}</div>
              </div>
              <div className="flex flex-row justify-between" >
                <div>Liquids</div>
                <div>{intake.liquid}</div>
              </div>
            </div>
            <div className="flex-1 text-gray-600">
              <h6 className="mb-2"><HashLink to="#output-header" className="text-blue-600">Outputs</HashLink></h6>
              <div className="flex flex-row justify-between" >
                <div>Urine</div>
                <div>{output.urine}</div>
              </div>
              <div className="flex flex-row justify-between" >
                <div >Emesis</div>
                <div >{output.emesis}</div>
              </div>
              <div className="flex flex-row justify-between" >
                <div>Bowel Movement</div>
                <div>{output.bowelMovement}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)}

AssessmentTableV2.defaultProps = {
  intake: {
    breakfast: '-',
    lunch: '-',
    dinner: '-',
    snackOne: '',
    snackTwo: '',
    snackThree: '',
    liquid: 0,
  },
  output: {
    urine: 0,
    emesis: 0,
    bowelMovement: 0
  }
}

export default AssessmentTableV2
