import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getClient, getUser } from '../../../../selectors';
import Component from '../../../../components/shiftReports/pca/v1/ShiftReport';
import {
    submitPcaShiftReport,
    updateShiftReport,
    captureShiftReportImage,
    createPcaPersonalCareTask,
    updatePcaPersonalCareTask,
    deletePcaPersonalCareTask,
    createHomeMakingTask,
    updateHomeMakingTask,
    deleteHomeMakingTask,
} from '../../../../actions';
import {
    getPcaPersonalCareTaskMatrix,
    getPcaPersonalCareTasks,
} from '../../../../selectors/pcaPersonalCareTask';
import {
    getHomeMakingTaskMatrix,
    getHomeMakingTasks,
} from '../../../../selectors/homeMakingTask';
import ShiftReportModal from '../../../../components/shiftReports/modals/ShiftReportModal';
import { generateTimeRange } from '../../../../utils/timeUtils';
import ShiftReportEndTime from '../../../../components/ShiftReportEndTime';
import { shiftReportTypes } from '../../../../utils/shiftTypes';

const requiredPersonalCareTaskCount = (start, end) => {
    const diff = moment.duration(moment(end).diff(moment(start))).asHours();
    return diff < 6 ? 3 : 5;
};

class ShiftReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditingEnd: false,
            isConfirming: false,
            validationErrors: [],
            verificationImage: null,
        };
    }

    _validate = () => {
        const errors = this._validatePersonalCareTasks([]);
        console.error('ERRORS: ', errors);
        return errors;
    };

    _personalCareTaskCount = () => {
        let count = 0;
        for (let [key] of Object.entries(this.props.pcaPersonalCareTaskMatrix)) {
            count += Object.entries(
                this.props.pcaPersonalCareTaskMatrix[key]
            ).length;
        }
        return count;
    };

    _validatePersonalCareTasks = (errors) => {
        const count = this._requiredPersonalCareTaskCount();
        if (this._personalCareTaskCount() < count) {
            errors.push(
                `At least ${count} personal care tasks must be completed.`
            );
        }

        return errors;
    };

    _requiredPersonalCareTaskCount = () => {
        return requiredPersonalCareTaskCount(
            this.props.shiftReport.start,
            this.props.shiftReport.end
        );
    };

    _toggleConfirmation = () => {
        this.setState({
            isConfirming: !this.state.isConfirming,
        });
    };

    _handleConfirmation = () => {
        this.setState({
            validationErrors: this._validate(),
            isConfirming: true,
        });
    };

    _handleSubmit = () => {
        this.props.updateShiftReport({
            ...this.props.shiftReport,
            recordedAt: moment().format(),
        });
    };

    _deletePersonalCareTasksAfter = (time) => {
        this.props.personalCareTasks
            .filter((pct) => {
                return moment(pct.recordedAt) >= moment(time);
            })
            .forEach((task) => {
                this.props.deletePersonalCareTask(task);
            });
    };

    _handleEndTimeSubmit = (endTime) => {
        this.props.updateShiftReport({
            ...this.props.shiftReport,
            ...endTime,
        });

        this._deletePersonalCareTasksAfter(endTime.end);

        this.setState({ isEditingEnd: false });
    };

    _captureImage = (image) => {
        console.log(
            'Image Size (kb): ',
            new TextEncoder().encode(image).length / 1024
        );
        this.props.captureShiftReportImage(
            this.props.shiftReport.id,
            image.split(',')[1]
        );
    };

    componentDidMount = () => {
        const shiftReport = this.props.shiftReport;
        if (this.props.user.id !== shiftReport.userId) {
            this.props.history.push(
                `/shift-report/${shiftReportTypes(shiftReport.type)}/v${
                    shiftReport.version
                }/${shiftReport.id}/view`
            );
        }
    };

    componentDidUpdate = (prevProps) => {
        if (
            prevProps.shiftReport.verificationImage !==
            this.props.shiftReport.verificationImage
        ) {
            this._handleSubmit();
        }

        if (
            prevProps.shiftReport.recordedAt !==
            this.props.shiftReport.recordedAt
        ) {
            this.props.submitPcaShiftReport({
                shiftReport: this.props.shiftReport,
                pcaPersonalCareTasks: this.props.pcaPersonalCareTasks,
                homeMakingTasks: this.props.homeMakingTasks,
                incidentReports: this.props.incidentReports,
                verificationImage: this.props.verificationImage,
            });
            this.props.history.push('/client');
        }
    };

    render = () => (
        <React.Fragment>
            <ShiftReportEndTime
                start={this.props.shiftReport.start}
                end={this.props.shiftReport.end}
                date={moment(this.props.shiftReport.start)}
                onSubmit={this._handleEndTimeSubmit}
                onBackClick={() => this.setState({ isEditingEnd: false })}
                isOpen={this.state.isEditingEnd}
            />
            <ShiftReportModal
                errors={this.state.validationErrors}
                user={this.props.user}
                shiftEndTime={this.props.shiftReport.end}
                onSubmit={this._handleSubmit}
                isOpen={this.state.isConfirming}
                onCancel={this._toggleConfirmation}
                onCapture={this._captureImage}
            />
            <Component
                {...this.props}
                times={generateTimeRange(
                    this.props.shiftReport.start,
                    this.props.shiftReport.end
                )}
                onEditEndTime={() => this.setState({ isEditingEnd: true })}
                onSubmit={this._handleConfirmation}
            />
        </React.Fragment>
    );
}

ShiftReport.propTypes = {
    user: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    shiftReport: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    submitPcaShiftReport: (shiftReportObj) =>
        dispatch(submitPcaShiftReport(shiftReportObj)),
    updateShiftReport: (shiftReport) =>
        dispatch(updateShiftReport(shiftReport)),
    captureShiftReportImage: (shiftReportId, image) => {
        dispatch(captureShiftReportImage(shiftReportId, image));
    },

    createPcaPersonalCareTask: (
        shiftReportId,
        type,
        recordedAt,
        status,
        comment
    ) =>
        dispatch(
            createPcaPersonalCareTask(
                shiftReportId,
                type,
                recordedAt,
                status,
                comment
            )
        ),
    updatePcaPersonalCareTask: (pcaPersonalCareTask) =>
        dispatch(updatePcaPersonalCareTask(pcaPersonalCareTask)),
    deletePcaPersonalCareTask: (personalCareTask) =>
        dispatch(deletePcaPersonalCareTask(personalCareTask)),
    createHomeMakingTask: (shiftReportId, type, recordedAt, status, comment) =>
        dispatch(
            createHomeMakingTask(
                shiftReportId,
                type,
                recordedAt,
                status,
                comment
            )
        ),
    updateHomeMakingTask: (homeMakingTask) =>
        dispatch(updateHomeMakingTask(homeMakingTask)),
    deleteHomeMakingTask: (homeMakingTask) =>
        dispatch(deleteHomeMakingTask(homeMakingTask)),
});

const mapStateToProps = (state, props) => ({
    user: getUser(state),
    client: getClient(state),
    shiftReport: state.entities.shiftReports.byId[props.match.params.id],
    pcaPersonalCareTasks: getPcaPersonalCareTasks(state, {
        shiftReportId: props.match.params.id,
    }),
    pcaPersonalCareTaskMatrix: getPcaPersonalCareTaskMatrix(state, {
        shiftReportId: props.match.params.id,
    }),
    homeMakingTasks: getHomeMakingTasks(state, {
        shiftReportId: props.match.params.id,
    }),
    homeMakingTaskMatrix: getHomeMakingTaskMatrix(state, {
        shiftReportId: props.match.params.id,
    }),
    incidentReports: state.entities.shiftReports.byId[
        props.match.params.id
    ].incidentReports.map((id) => state.entities.incidentReports.byId[id]),
    verificationImage: state.entities.shiftReports.byId[props.match.params.id]
        .verificationImage
        ? state.entities.verificationImages.byId[
              state.entities.shiftReports.byId[props.match.params.id]
                  .verificationImage
          ]
        : null,
});

const Container = connect(mapStateToProps, mapDispatchToProps)(ShiftReport);

export default Container;
