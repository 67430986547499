import React from "react";
import Modal from "../components/Modal";
import * as Yup from "yup";
import SecondaryButton from "../components/SecondaryButton";
import moment from "moment";
import { timeOptions } from "../utils/timeUtils";
import TimeSelect from "../components/TimeSelect";
import Input from "../components/form/Input";
import { Formik } from "formik";
import Error from "../components/form/Error";
import CenterContainer from "../components/layout/CenterContainer";
import SaveButton from "./form/SaveButton";
import PrimaryButton from "./form/PrimaryButton";
import vitalValidations from "../utils/vitalValidations";
import Checkbox from "./Checkbox";

class VitalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientRefused: props.vital.clientRefused,
      step: 1,
      vital: props.vital,
    };

    this.start = props.start;
    this.end = props.end;
  }

  _handleSubmit = (vital) => {
    if (vitalValidations(vital).length > 0) {
      this.setState({
        step: 2,
        vital,
      });
    } else {
      this._submit(vital);
    }
  };

  _submit = (vital) => {
    this.props.onSubmit(vital);
  };

  _initialTime = (time) => {
    const { start, end } = this.props;
    if (time) {
      return timeOptions(start, end).find((option) => {
        return time === option.value;
      });
    }

    return this._defaultTime();
  };

  _defaultTime = () => {
    const { start, end } = this.props;
    return timeOptions(start, end).find((option) => {
      const diff = moment
        .duration(moment(option.value).diff(moment()))
        .asMinutes();
      return Math.abs(diff) < 7.5;
    });
  };

  _validationSchema = () => {
    if (!this.state.clientRefused) {
      return Yup.object().shape({
        recordedAt: Yup.object().required("Taken at is required."),
        bloodPressureSystolic: Yup.number()
          .max(300, "Too high. Please check again.")
          .min(50, "Too low. Please check again.")
          .required("Systolic is required."),
        bloodPressureDiastolic: Yup.number()
          .max(200, "Too high. Please check again.")
          .min(20, "Too low. Please check again.")
          .required("Diastolic is required."),
        pulse: Yup.number()
          .max(300, "Too high. Please check again.")
          .min(20, "Too low. Please check again.")
          .required("Pulse is required."),
        temperature: Yup.number("Must be a number")
          .max(120, "Too high. Please check again.")
          .min(80, "Too low. Please check again.")
          .required("Temperature is required."),
        respirationRate: Yup.number()
          .max(200, "Too high. Please check again.")
          .min(5, "Too low. Please check again.")
          .required("Respiration rate is required."),
        oxygenSaturationLevel: Yup.number()
          .max(100, "Too high. Please check again.")
          .min(0, "Too low. Please check again.")
          .required("Saturation level is required."),
        weight: Yup.number("Must be a number")
          .max(999, "Too high. Please check again.")
          .min(0, "Too low. Please check again.")
          .required("Weight is Required"),
      });
    } else {
      return Yup.object().shape({
        recordedAt: Yup.object().required("Taken at is required."),
        bloodPressureSystolic: Yup.number()
          .max(300, "Too high. Please check again.")
          .min(50, "Too low. Please check again."),
        bloodPressureDiastolic: Yup.number()
          .max(200, "Too high. Please check again.")
          .min(20, "Too low. Please check again."),
        pulse: Yup.number()
          .max(300, "Too high. Please check again.")
          .min(20, "Too low. Please check again."),
        temperature: Yup.number("Must be a number")
          .max(120, "Too high. Please check again.")
          .min(80, "Too low. Please check again."),
        respirationRate: Yup.number()
          .max(200, "Too high. Please check again.")
          .min(5, "Too low. Please check again."),
        oxygenSaturationLevel: Yup.number()
          .max(100, "Too high. Please check again.")
          .min(0, "Too low. Please check again."),
        weight: Yup.number("Must be a number")
          .max(999, "Too high. Please check again.")
          .min(0, "Too low. Please check again."),
      });
    }
  };

  _handleBack = () => {
    this.setState({
      step: 1,
    });
  };

  _toggleClientRefused = () => {
    this.setState({
      clientRefused: !this.state.clientRefused,
    });
  };

  _toggleOxygenInUse = () => {
    this.setState({
      vital: {
        oxygenInUse: !this.state.vital.oxygenInUse,
      },
    });
  };

  render = () => (
    <React.Fragment>
      <Modal isOpen={true}>
        <CenterContainer>
          {this.state.step === 2 && (
            <div className="max-w-6xl bg-white rounded-lg shadow-lg">
              <h1
                id="confirmation-heading"
                className="font-bold text-lg p-8 pb-0 mb-4"
              >
                Vital Measurements
              </h1>
              <div className="p-8 pt-0">
                <p className="mb-2 text-red-600">
                  Please refer to the "Plan of Care" for any further course of
                  action:
                </p>
                <ul className="list-disc ml-8">
                  {vitalValidations(this.state.vital).map((notification) => (
                    <li>{notification}</li>
                  ))}
                </ul>
              </div>
              <div className="p-4 px-8 text-right bg-gray-100 rounded-b-lg">
                <SecondaryButton
                  id="confirmation-secondary-button"
                  className="inline-block mr-4"
                  type="button"
                  onClick={this._handleBack}
                >
                  Edit
                </SecondaryButton>
                <PrimaryButton
                  id="confirmation-primary-button"
                  type="button"
                  onClick={() => this._submit(this.state.vital)}
                >
                  Ok, Close
                </PrimaryButton>
              </div>
            </div>
          )}
          {this.state.step === 1 && (
            <Formik
              validationSchema={this._validationSchema()}
              initialValues={{
                clientRefused: this.state.vital.clientRefused,
                recordedAt: this._initialTime(this.state.vital.recordedAt),
                bloodPressureSystolic: this.state.vital.bloodPressureSystolic
                  ? this.state.vital.bloodPressureSystolic
                  : "",
                bloodPressureDiastolic: this.state.vital.bloodPressureDiastolic
                  ? this.state.vital.bloodPressureDiastolic
                  : "",
                pulse: this.state.vital.pulse ? this.state.vital.pulse : "",
                temperature: this.state.vital.temperature
                  ? this.state.vital.temperature
                  : "",
                oxygenInUse: this.state.vital.oxygenInUse
                  ? this.state.vital.oxygenInUse
                  : "",
                oxygenSaturationLevel: this.state.vital.oxygenSaturationLevel
                  ? this.state.vital.oxygenSaturationLevel
                  : "",
                respirationRate: this.state.vital.respirationRate
                  ? this.state.vital.respirationRate
                  : "",
                weight: this.state.vital.weight ? this.state.vital.weight : "",
              }}
              onSubmit={(values) => {
                this._handleSubmit({
                  ...values,
                  recordedAt: values.recordedAt.value,
                  clientRefused: this.state.clientRefused,
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className="max-w-6xl bg-white rounded-lg shadow-lg"
                >
                  <h1
                    id="vitals-heading"
                    className="font-bold text-lg p-8 pb-0"
                  >
                    Vital Measurements
                  </h1>
                  <div className="flex mb-4 p-8 pb-0">
                    <div className="w-1/2 mr-8">
                      <label className="block mb-2">Taken At</label>
                      <TimeSelect
                        id="taken-at-select"
                        value={values.recordedAt}
                        options={timeOptions(this.props.start, this.props.end)}
                        placeholder="Choose a time..."
                        onChange={(time) => setFieldValue("recordedAt", time)}
                      />
                      {errors.recordedAt && touched.recordedAt ? (
                        <Error>{errors.recordedAt}</Error>
                      ) : null}

                      <div className="flex mt-8 w-full">
                        <button
                          id="oxygen-in-use"
                          type="button"
                          onClick={() => {
                            setFieldValue("oxygenInUse", !values.oxygenInUse);
                            this._toggleOxygenInUse();
                          }}
                          className="bg-gray-100 gray-600  border-gray-200 border-2 hover:border-gray-400 rounded-lg p-4 flex items-center justify-between text-left w-full"
                        >
                          <label className="block">Oxygen in Use?</label>
                          <Checkbox
                            checked={this.state.vital.oxygenInUse}
                            className="text-3xl"
                          />
                        </button>
                      </div>

                      {errors.oxygenInUse && touched.oxygenInUse && (
                        <Error>{errors.oxygenInUse}</Error>
                      )}
                    </div>
                    <div className="w-1/3 mr-8">
                      <div>
                        <label className="block mb-2">Blood Pressure</label>
                        <Input
                          id="vitals-pressure-systolic-input"
                          type="number"
                          autoComplete="off"
                          name="bloodPressureSystolic"
                          onBlur={handleBlur}
                          value={values.bloodPressureSystolic}
                          onChange={handleChange}
                          placeholder="120"
                        />
                        {errors.bloodPressureSystolic &&
                          touched.bloodPressureSystolic && (
                            <Error>{errors.bloodPressureSystolic}</Error>
                          )}
                      </div>
                      <div>
                        <Input
                          id="vitals-pressure-diastolic-input"
                          type="number"
                          autoComplete="off"
                          name="bloodPressureDiastolic"
                          onBlur={handleBlur}
                          value={values.bloodPressureDiastolic}
                          onChange={handleChange}
                          placeholder="80"
                          className="mt-3"
                        />
                        {errors.bloodPressureDiastolic &&
                          touched.bloodPressureDiastolic && (
                            <Error>{errors.bloodPressureDiastolic}</Error>
                          )}
                      </div>
                    </div>
                    <div className="w-1/3 mr-8">
                      <label className="block mb-2">Pulse</label>
                      <Input
                        id="vitals-pulse-input"
                        name="pulse"
                        autoComplete="off"
                        value={values.pulse}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="90"
                      />
                      {errors.pulse && touched.pulse && (
                        <Error>{errors.pulse}</Error>
                      )}
                    </div>
                    <div className="bg-white w-1/3 rounded-lg">
                      <label className="block mb-2">Respiration Rate</label>
                      <Input
                        id="vitals-respiration-input"
                        type="number"
                        autoComplete="off"
                        name="respirationRate"
                        onBlur={handleBlur}
                        value={values.respirationRate}
                        onChange={handleChange}
                        placeholder="15"
                      />
                      {errors.respirationRate && touched.respirationRate && (
                        <Error>{errors.respirationRate}</Error>
                      )}
                    </div>
                  </div>
                  <div className="flex p-8 pb-8 pt-4">
                    <div className="w-1/2 mr-8"></div>
                    <div className="w-1/3 mr-8">
                      <label className="block mb-2">Temperature (&deg;F)</label>
                      <Input
                        id="vitals-temperature-input"
                        type="number"
                        autoComplete="off"
                        name="temperature"
                        onBlur={handleBlur}
                        value={values.temperature}
                        onChange={handleChange}
                        placeholder="98.6"
                      />
                      {errors.temperature && touched.temperature && (
                        <Error>{errors.temperature}</Error>
                      )}
                    </div>
                    <div className="w-1/3 mr-8">
                      <label className="block mb-2">
                        O<sub>2</sub> Saturation (%)
                      </label>
                      <Input
                        id="vitals-oxygen-input"
                        type="number"
                        autoComplete="off"
                        name="oxygenSaturationLevel"
                        onBlur={handleBlur}
                        value={values.oxygenSaturationLevel}
                        onChange={handleChange}
                        placeholder="100"
                      />
                      {errors.oxygenSaturationLevel &&
                        touched.oxygenSaturationLevel && (
                          <Error>{errors.oxygenSaturationLevel}</Error>
                        )}
                    </div>
                    <div className="w-1/3">
                      <label className="block mb-2">Weight (pounds)</label>
                      <Input
                        id="vitals-weight-input"
                        type="number"
                        autoComplete="off"
                        name="weight"
                        value={values.weight}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="140"
                      />
                      {errors.weight && touched.weight && (
                        <Error id="error-weight-validation">
                          {errors.weight}
                        </Error>
                      )}
                    </div>
                  </div>
                  <div className="p-4 px-8 text-right bg-gray-100 rounded-b-lg flex justify-between items-center">
                    <div>
                      <button
                        id="client-refused-button"
                        type="button"
                        onClick={() => {
                          setFieldValue("clientRefused", !values.clientRefused);
                          this._toggleClientRefused();
                        }}
                        className={
                          this.state.clientRefused
                            ? "flex items-center text-left w-full"
                            : "flex items-center w-full text-left"
                        }
                      >
                        <Checkbox
                          checked={this.state.clientRefused}
                          className="text-3xl"
                        />
                        Client refused some or all
                      </button>
                    </div>
                    <div>
                      <SecondaryButton
                        id="vitals-secondary-button"
                        className="inline-block mr-4"
                        type="button"
                        onClick={this.props.onCancel}
                      >
                        Cancel
                      </SecondaryButton>
                      <SaveButton id="vitals-save-button" type="submit">
                        Save
                      </SaveButton>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </CenterContainer>
      </Modal>
    </React.Fragment>
  );
}

export default VitalForm;
