import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Header from '../../../Header';
import IncidentReports from '../../../../containers/IncidentReports';
import {
    pcaPersonalCareTasks,
    pcaPersonalCareTaskLabels,
    homeMakingTasks,
    homeMakingTaskLabels,
} from '../../../../constants';
import Container from '../../../layout/Container';
import PrimaryButton from '../../../../components/form/PrimaryButton';
import ShiftReportHeader from '../../../ShiftReportHeader';
import PersonalCareTaskWidget from '../../../PersonalCareTaskWidget';

const ShiftReport = (props) => {
    return (
        <Container>
            <Header client={props.client} user={props.user} />
            <div className='p-8'>
                <ShiftReportHeader
                    shiftReport={props.shiftReport}
                    author={props.user}
                    onEditEndTime={props.onEditEndTime}
                >
                    <Link
                        to='/client'
                        className='cursor-pointer bg-gray-400 p-3 px-6 text-gray-800 mr-2 inline-block font-semibold rounded-full'
                    >
                        <i className='fas fa-save inline-block mr-2' /> Save and
                        Exit
                    </Link>

                    <PrimaryButton
                        id='report-submit-button'
                        onClick={props.onSubmit}
                    >
                        <i className='fas fa-file-upload mr-2 inline-block' />{' '}
                        Submit Shift Report
                    </PrimaryButton>
                </ShiftReportHeader>

                <h1 id='personal-care-heading' className='text-2xl mb-4'>
                    Personal Care Tasks
                </h1>
                <table
                    id='personal-care-time-table'
                    className='w-full mb-8 table--rounded shadow-md rounded-lg'
                >
                    <thead>
                        <tr className='bg-gray-100'>
                            <th></th>
                            {props.times.map((time) => (
                                <th className='pr-4 pb-4 pt-4' key={time}>
                                    {moment(time).format('H:mm')}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody id='personal-care-table'>
                        {pcaPersonalCareTasks.map((type, i) => (
                            <tr
                                className={
                                    i % 2 === 0 ? 'bg-white' : 'bg-gray-100'
                                }
                                key={i}
                            >
                                <td className='p-4 leading-tight w-72'>
                                    <span className='block'>
                                        {pcaPersonalCareTaskLabels[type]}
                                    </span>
                                </td>
                                {props.times.map((time) => (
                                    <td
                                        className='pr-4 text-center py-2'
                                        key={time}
                                    >
                                        <PersonalCareTaskWidget
                                            type={type}
                                            recordedAt={time}
                                            shiftReportId={props.shiftReport.id}
                                            personalCareTask={
                                                props.pcaPersonalCareTaskMatrix[
                                                    type
                                                ] &&
                                                props.pcaPersonalCareTaskMatrix[
                                                    type
                                                ][time]
                                                    ? props
                                                          .pcaPersonalCareTaskMatrix[
                                                          type
                                                      ][time]
                                                    : null
                                            }
                                            deletePersonalCareTask={
                                                props.deletePcaPersonalCareTask
                                            }
                                            updatePersonalCareTask={
                                                props.updatePcaPersonalCareTask
                                            }
                                            createPersonalCareTask={
                                                props.createPcaPersonalCareTask
                                            }
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h1 id='home-making-task-heading' className='text-2xl mb-4'>
                    Homemaking Tasks
                </h1>
                <table
                    id='home-making-task-time-table'
                    className='w-full mb-8 table--rounded shadow-md rounded-lg'
                >
                    <thead>
                        <tr className='bg-gray-100'>
                            <th></th>
                            {props.times.map((time) => (
                                <th className='pr-4 pb-4 pt-4' key={time}>
                                    {moment(time).format('H:mm')}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody id='personal-care-table'>
                        {homeMakingTasks.map((type, i) => (
                            <tr
                                className={
                                    i % 2 === 0 ? 'bg-white' : 'bg-gray-100'
                                }
                                key={i}
                            >
                                <td className='p-4 leading-tight w-72'>
                                    <span className='block'>
                                        {homeMakingTaskLabels[type]}
                                    </span>
                                </td>
                                {props.times.map((time) => (
                                    <td
                                        className='pr-4 text-center py-2'
                                        key={time}
                                    >
                                        <PersonalCareTaskWidget
                                            type={type}
                                            recordedAt={time}
                                            shiftReportId={props.shiftReport.id}
                                            personalCareTask={
                                                props.homeMakingTaskMatrix[
                                                    type
                                                ] &&
                                                props.homeMakingTaskMatrix[
                                                    type
                                                ][time]
                                                    ? props
                                                          .homeMakingTaskMatrix[
                                                          type
                                                      ][time]
                                                    : null
                                            }
                                            deletePersonalCareTask={
                                                props.deleteHomeMakingTask
                                            }
                                            updatePersonalCareTask={
                                                props.updateHomeMakingTask
                                            }
                                            createPersonalCareTask={
                                                props.createHomeMakingTask
                                            }
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h1 className='text-2xl mb-4'>Field Notes</h1>
                <div className='mb-8 flex flex-wrap'>
                    <IncidentReports shiftReport={props.shiftReport} />
                </div>
            </div>
            <p className='invisible'>PCA TESTING FORM</p>
        </Container>
    );
};

export default ShiftReport;
