import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Component from '../components/Client';
import Loader from '../components/Loader';
import CenterContainer from '../components/layout/CenterContainer';
import {
    fetchShiftReportsByClientId,
    deleteShiftReport,
    fetchClientIfNeeded,
    submitHhaShiftReport,
    submitPcaShiftReport,
    submitClinicalShiftReport,
} from '../actions';
import Modal from '../components/Modal';
import {
    getUser,
    getClient,
    getEmployees,
    getUserShiftReports,
    getSubmittedClientShiftReports,
} from '../selectors';
import SecondaryButton from '../components/SecondaryButton';
import DangerButton from '../components/DangerButton';
import {
    SHIFT_REPORT_STATUS_OPEN,
    SHIFT_REPORT_STATUS_SUBMITTED,
    SHIFT_REPORT_STATUS_FAILED,
    SHIFT_REPORT_STATUS_PENDING,
    SHIFT_REPORT_STATUS_FAILED_NETWORK_CONNECTIVITY,
} from '../constants';

class Client extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            perPage: 10,
            confirmDeleteShiftReport: false,
            deleteShiftReportId: null,
        };
    }

    componentDidMount = () => {
        this._autoDelete();
        if (this._shouldUpdateClient()) {
            this.props.fetchClientIfNeeded(this.props.clientId);
            this.props.fetchShiftReportsByClientId(this.props.clientId);
        }
    };

    componentDidUpdate = (prevProps) => {
        const before = prevProps.shiftReports.filter((shiftReport) => {
            return shiftReport.status === SHIFT_REPORT_STATUS_SUBMITTED;
        }).length;

        const after = this.props.shiftReports.filter((shiftReport) => {
            return shiftReport.status === SHIFT_REPORT_STATUS_SUBMITTED;
        }).length;
        if (after > before) {
            this.props.fetchShiftReportsByClientId(this.props.clientId);
            this.setState({
                isSubmitting: false,
            });
        }
    };

    _reSubmitShiftReport = (shiftReport) => {
        this.setState({
            isSubmitting: true,
        });
        if (shiftReport.type === 'rn') {
            this.props.submitClinicalShiftReport({
                shiftReport,
                painAssessments: shiftReport.painAssessments.map(
                    (id) => this.props.painAssessments.byId[id]
                ),
                assessment: this.props.assessments.byId[shiftReport.assessment],
                intake: this.props.intakes.byId[shiftReport.intake],
                output: this.props.outputs.byId[shiftReport.output],
                woundCares: shiftReport.woundCares.map(
                    (id) => this.props.woundCares.byId[id]
                ),
                vitals: shiftReport.vitals.map(
                    (id) => this.props.vitals.byId[id]
                ),
                clinicalContinuationNotes:
                    shiftReport.clinicalContinuationNotes.map(
                        (id) => this.props.clinicalContinuationNotes.byId[id]
                    ),
                verificationImage:
                    this.props.verificationImages.byId[
                        shiftReport.verificationImage
                    ],
            });
        }
        if (shiftReport.type === 'hha') {
            this.props.submitHhaShiftReport({
                shiftReport,
                personalCareTasks: shiftReport.personalCareTasks.map(
                    (id) => this.props.personalCareTasks.byId[id]
                ),
                activities: shiftReport.activities.map(
                    (id) => this.props.activities.byId[id]
                ),
                vitals: shiftReport.vitals.map(
                    (id) => this.props.vitals.byId[id]
                ),
                incidentReports: shiftReport.incidentReports.map(
                    (id) => this.props.incidentReports.byId[id]
                ),
                verificationImage:
                    this.props.verificationImages.byId[
                        shiftReport.verificationImage
                    ],
            });
        }
        if (shiftReport.type === 'pca') {
            this.props.submitPcaShiftReport({
                shiftReport,
                pcaPersonalCareTasks: shiftReport.pcaPersonalCareTasks.map(
                    (id) => this.props.pcaPersonalCareTasks.byId[id]
                ),
                homeMakingTasks: shiftReport.homeMakingTasks.map(
                    (id) => this.props.homeMakingTasks.byId[id]
                ),
                verificationImage:
                    this.props.verificationImages.byId[
                        shiftReport.verificationImage
                    ],
            });
        }
    };

    _autoDelete = () => {
        this._deletableShiftReports().forEach((shiftReport) => {
            this.props.deleteShiftReport(shiftReport.id, this.props.clientId);
        });
    };

    _deletableShiftReports = () => {
        return this.props.submittedShiftReports.filter((shiftReport) => {
            const start = moment(shiftReport.start);
            return moment.duration(moment().diff(start)).asDays() > 7;
        });
    };

    _handleCancelDeleteShiftReport = () => {
        this.setState({
            confirmDeleteShiftReport: false,
            deleteShiftReportId: null,
        });
    };

    _handleConfirmDeleteShiftReport = () => {
        this.props.deleteShiftReport(
            this.state.deleteShiftReportId,
            this.props.clientId
        );
        this.setState({
            confirmDeleteShiftReport: false,
            deleteShiftReportId: null,
        });
    };

    _handleDeleteShiftReport = (id) => {
        this.setState({
            confirmDeleteShiftReport: true,
            deleteShiftReportId: id,
        });
    };

    _numPages = () => {
        return Math.ceil(
            this.props.submittedShiftReports.length / this.state.perPage
        );
    };

    _shouldUpdateClient = () => {
        return Date.now() - this.props.clientLastUpdated > 900000;
    };

    _openShiftReports = () => {
        return this.props.shiftReports.filter((shiftReport) => {
            return (
                moment(shiftReport.createdAt) >
                    moment().startOf('day').subtract(14, 'days') &&
                (shiftReport.status === SHIFT_REPORT_STATUS_OPEN ||
                    shiftReport.status === SHIFT_REPORT_STATUS_FAILED ||
                    shiftReport.status ===
                        SHIFT_REPORT_STATUS_FAILED_NETWORK_CONNECTIVITY ||
                    shiftReport.status === SHIFT_REPORT_STATUS_PENDING)
            );
        });
    };

    _sortedShiftReports = () => {
        return this.props.submittedShiftReports.concat().sort((a, b) => {
            if (moment(b.start).unix() < moment(a.start).unix()) {
                return -1;
            }

            if (moment(b.start).unix() > moment(a.start).unix()) {
                return 1;
            }

            return 0;
        });
    };

    _start = () => {
        return this.props.page === 1
            ? 0
            : (this.props.page - 1) * this.state.perPage;
    };

    _end = () => {
        return this._start() + this.state.perPage;
    };

    _filteredShiftReports = () => {
        return this._sortedShiftReports().slice(this._start(), this._end());
    };

    _pages = () => {
        const pages = [];
        for (let i = 1; i <= this._numPages(); i++) {
            pages.push(i);
        }

        return pages;
    };

    _prev = () => {
        return this.props.page === 1 ? 1 : this.props.page - 1;
    };

    _next = () => {
        return this.props.page === this._numPages()
            ? this._numPages()
            : this.props.page + 1;
    };

    render = () => (
        <>
            {this.props.isFetching && (
                <Loader>Updating client information...</Loader>
            )}
            <Modal isOpen={this.state.confirmDeleteShiftReport}>
                <CenterContainer>
                    <div className='bg-white rounded-lg shadow-lg w-1/2'>
                        <div className='p-8'>
                            <div className='font-bold text-lg pb-0 mb-4'>
                                Are you sure you want to delete this shift
                                report?
                            </div>
                            <p>
                                Once the shift report has been deleted you will
                                no longer be able to make any changes or submit
                                it.
                            </p>
                        </div>
                        <div className='text-right p-4 bg-gray-100 rounded-b-lg'>
                            <SecondaryButton
                                onClick={this._handleCancelDeleteShiftReport}
                                className='mr-4'
                            >
                                No, Cancel
                            </SecondaryButton>
                            <DangerButton
                                onClick={this._handleConfirmDeleteShiftReport}
                            >
                                Yes, Delete
                            </DangerButton>
                        </div>
                    </div>
                </CenterContainer>
            </Modal>
            <Component
                reSubmitShiftReport={this._reSubmitShiftReport}
                userShiftReports={this._openShiftReports()}
                submittedShiftReports={this._filteredShiftReports()}
                onDeleteShiftReport={this._handleDeleteShiftReport}
                client={this.props.client}
                user={this.props.user}
                employees={this.props.employees}
                pages={this._pages()}
                page={this.props.page}
                prev={this._prev()}
                next={this._next()}
            />
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    fetchClientIfNeeded: (id) => dispatch(fetchClientIfNeeded(id)),
    deleteShiftReport: (id, clientId) =>
        dispatch(deleteShiftReport(id, clientId)),
    fetchShiftReportsByClientId: (clientId) =>
        dispatch(fetchShiftReportsByClientId(clientId)),
    submitHhaShiftReport: (shiftReportObj) =>
        dispatch(submitHhaShiftReport(shiftReportObj)),
    submitPcaShiftreport: (shiftReportObj) =>
        dispatch(submitPcaShiftReport(shiftReportObj)),
    submitClinicalShiftReport: (shiftReportObj) =>
        dispatch(submitClinicalShiftReport(shiftReportObj)),
});

const mapStateToProps = (state, ownProps) => ({
    isFetching: state.clients.isFetching,
    clientId: state.settings.clientId,
    clientLastUpdated: state.clients.lastUpdated,
    shiftReports: getUserShiftReports(state),
    submittedShiftReports: getSubmittedClientShiftReports(state),
    client: getClient(state),
    user: getUser(state),
    employees: getEmployees(state),
    page: ownProps.match.params.page ? parseInt(ownProps.match.params.page) : 1,
    personalCareTasks: state.entities.personalCareTasks,
    pcaPersonalCareTasks: state.entities.pcaPersonalCareTasks,
    activities: state.entities.activities,
    incidentReports: state.entities.incidentReports,
    vitals: state.entities.vitals,
    verificationImages: state.entities.verificationImages,
    assessments: state.entities.assessments,
    painAssessments: state.entities.painAssessments,
    intakes: state.entities.intakes,
    outputs: state.entities.outputs,
    woundCares: state.entities.woundCares,
    clinicalContinuationNotes: state.entities.clinicalContinuationNotes,
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Client);

export default Container;
