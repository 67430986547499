import pick from 'lodash/pick'
import {
  basicAssessmentMappings,
  digestiveAssessmentMappings,
  cardiovascularAssessmentMappings,
  neuroAssessmentMappings,
  pulmonaryAssessmentMappings
} from '../constants'

// Functions to define how to group the various assessment keys semantically
export const basicAssessmentFields  = Object.keys(basicAssessmentMappings);
export const basicDispositionFields = basicAssessmentFields.filter(x => x.startsWith('disposition_'));
export const basicHeadFields        = basicAssessmentFields.filter(x => x.startsWith('head_'));
export const basicEyeFields         = basicAssessmentFields.filter(x => x.startsWith('eyes_'));
export const basicEarFields         = basicAssessmentFields.filter(x => x.startsWith('ears_'));
export const basicNoseFields        = basicAssessmentFields.filter(x => x.startsWith('nose_'));
export const basicMouthFields       = basicAssessmentFields.filter(x => x.startsWith('mouth_'));
export const basicTongueFields      = basicAssessmentFields.filter(x => x.startsWith('tongue_'));

export const digestiveAssessmentFields  = Object.keys(digestiveAssessmentMappings);
export const digestiveBowelsFields      = digestiveAssessmentFields.filter(x => x.startsWith('bowels_'));
export const digestiveBowelSoundsFields = digestiveAssessmentFields.filter(x => x.startsWith('bowel_sounds_'));
export const digestiveBladderFields     = digestiveAssessmentFields.filter(x => x.startsWith('bladder_'));
export const digestiveAbdomenFields     = digestiveAssessmentFields.filter(x => x.startsWith('abdomen_'));

export const cardioAssessmentFields      = Object.keys(cardiovascularAssessmentMappings);
export const cardioApicalFields          = cardioAssessmentFields.filter(x => x.startsWith('apical_'));
export const cardioEdemaFields           = cardioAssessmentFields.filter(x => x.startsWith('edema_'));
export const cardioCapillaryRefillFields = cardioAssessmentFields.filter(x => x.startsWith('capillary_'));
export const cardioPedalPulsesFields     = cardioAssessmentFields.filter(x => x.startsWith('pedal_'));
export const cardioRadialPulsesFields    = cardioAssessmentFields.filter(x => x.startsWith('radial_'));

export const neuroAssessmentFields  = Object.keys(neuroAssessmentMappings);
export const neuroConsciousnessFields = neuroAssessmentFields.filter(x => x.startsWith('consciousness_'));

export const pulmonaryAssessmentFields   = Object.keys(pulmonaryAssessmentMappings);
export const pulmonaryChestFields        = pulmonaryAssessmentFields.filter(x => x.startsWith('chest_expansion'));
export const pulmonaryBreathSoundsFields = pulmonaryAssessmentFields.filter(x => x.startsWith('breath_sounds'));
export const pulmonaryRespiratoryFields  = pulmonaryAssessmentFields.filter(x => x.startsWith('respiratory'));
export const pulmonaryQualityFields      = pulmonaryAssessmentFields.filter(x => x.startsWith('quality'));
export const pulmonaryCoughFields        = pulmonaryAssessmentFields.filter(x => x.startsWith('cough'));
export const pulmonarySputumFields       = pulmonaryAssessmentFields.filter(x => x.startsWith('sputum'));
export const pulmonaryOxygenFields       = pulmonaryAssessmentFields.filter(x => x.startsWith('oxygen'));

// Functions to get a subset object from a larger assessment object
//
const extractAssessment                   = (assessment, fields) => pick(assessment, fields);

export const extractBasicAssessment       = (assessment) => extractAssessment(assessment, basicAssessmentFields);
export const extractDispositionAssessment = (assessment) => extractAssessment(assessment, basicDispositionFields);
export const extractHeadAssessment        = (assessment) => extractAssessment(assessment, basicHeadFields);
export const extractEyeAssessment         = (assessment) => extractAssessment(assessment, basicEyeFields);
export const extractEarAssessment         = (assessment) => extractAssessment(assessment, basicEarFields);
export const extractNoseAssessment        = (assessment) => extractAssessment(assessment, basicNoseFields);
export const extractMouthAssessment       = (assessment) => extractAssessment(assessment, basicMouthFields);
export const extractTongueAssessment      = (assessment) => extractAssessment(assessment, basicTongueFields);

export const extractDigestiveAssessment   = (assessment) => extractAssessment(assessment, digestiveAssessmentFields);
export const extractBowelsAssessment      = (assessment) => extractAssessment(assessment, digestiveBowelsFields);
export const extractBowelSoundsAssessment = (assessment) => extractAssessment(assessment, digestiveBowelSoundsFields);
export const extractBladderAssessment     = (assessment) => extractAssessment(assessment, digestiveBladderFields);
export const extractAbdomenAssessment     = (assessment) => extractAssessment(assessment, digestiveAbdomenFields);

export const extractCardioAssessment          = (assessment) => extractAssessment(assessment, cardioAssessmentFields);
export const extractApicalAssessment          = (assessment) => extractAssessment(assessment, cardioApicalFields);
export const extractEdemaAssessment           = (assessment) => extractAssessment(assessment, cardioEdemaFields);
export const extractCapillaryRefillAssessment = (assessment) => extractAssessment(assessment, cardioCapillaryRefillFields);
export const extractPedalPulsesAssessment     = (assessment) => extractAssessment(assessment, cardioPedalPulsesFields);
export const extractRadialPulsesAssessment    = (assessment) => extractAssessment(assessment, cardioRadialPulsesFields);

export const extractNeuroAssessment              = (assessment) => extractAssessment(assessment, neuroAssessmentFields);
export const extractConsciousnessAssessment      = (assessment) => extractAssessment(assessment, neuroConsciousnessFields);

export const extractPulmonaryAssessment    = (assessment) => extractAssessment(assessment, pulmonaryAssessmentFields);
export const extractChestAssessment        = (assessment) => extractAssessment(assessment, pulmonaryChestFields);
export const extractBreathSoundsAssessment = (assessment) => extractAssessment(assessment, pulmonaryBreathSoundsFields);
export const extractRespiratoryAssessment  = (assessment) => extractAssessment(assessment, pulmonaryRespiratoryFields);
export const extractQualityAssessment      = (assessment) => extractAssessment(assessment, pulmonaryQualityFields);
export const extractCoughAssessment        = (assessment) => extractAssessment(assessment, pulmonaryCoughFields);
export const extractSputumAssessment       = (assessment) => extractAssessment(assessment, pulmonarySputumFields);
export const extractOxygenAssessment       = (assessment) => extractAssessment(assessment, pulmonaryOxygenFields);

// Given an assessment object, and a mapping of values, returns a string
// of concatenated true values from the assessment
// example:
//
// getCheckedValues({happy: true, bemused: true, sad: false}, {happy: 'Happy', bemused: 'Bemused', sad: 'Sad'})
// #=> 'Happy, Bemused'
export const getCheckedValues = (assessment, assessmentMappings) => {
  return Object
    .keys(assessment)
    .filter(x => assessment[x] && !x.endsWith("_other"))
    .map(x => x.includes("_value") ? assessment[x] : assessmentMappings[x])
    .sort()
    .join(', ');
}

export const assessmentComplete = (assessment) => {
  return Object.values(assessment).some(x => {
    if(Array.isArray(x)) {
      return x.length > 0
    } else {
      return x
    }
  });
};