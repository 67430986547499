import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { InputWithCharsLeft } from '../../../../components/form/Input'
import Error from '../../../../components/form/Error'
import {intakeOptions} from '../../../../constants'

const Intake = (props) => {
  const [intake, setIntake] = useState({
    breakfast: props.intake.breakfast || "",
    lunch: props.intake.lunch || "",
    dinner: props.intake.dinner || "",
    snackOne: props.intake.snackOne || "",
    snackTwo: props.intake.snackTwo || "",
    snackThree: props.intake.snackThree || "",
    liquid: props.intake.liquid || 0,
  })

  const { updateIntake } = props
  const intakeId = props.intake.id

  useEffect(() => {
    updateIntake({
      id: intakeId,
      ...intake
    })
  }, [intake, intakeId, updateIntake]);

  const _handleChange = (e) => {
    let { name, value } = e.target;
    setIntake({...intake, [name]: value})
  }

  const _handleSelectChange = (option, name) => {
    setIntake({...intake, [name]: option})
  }

  return (
    <div className="ml-1 my-2 bg-white rounded-lg shadow-md">
      <form>
        <label className="p-3 pb-3 pl-4 flex justify-between items-center">
          Breakfast
          <Select
            id="intake-breakfast-select"
            name="breakfast"
            options={intakeOptions}
            className="react-select-container--clinical w-56"
            classNamePrefix="react-select"
            placeholder="Select one..."
            value={intakeOptions.find(opt => opt.value === intake.breakfast)}
            onChange={(selected, action) => {
              _handleSelectChange(selected.value, action.name);
            }}
          />
        </label>

        <label className="p-3 pb-3 pl-4 bg-gray-100 flex justify-between items-center">
          Lunch
          <Select
            id="intake-lunch-select"
            name="lunch"
            options={intakeOptions}
            className="react-select-container--clinical w-56"
            classNamePrefix="react-select"
            placeholder="Select one..."
            value={intakeOptions.find(opt => opt.value === intake.lunch)}
            onChange={(selected, action) => {
              _handleSelectChange(selected.value, action.name)
            }}
          />
        </label>
        <label className="p-3 pb-3 pl-4 flex justify-between items-center">
          Dinner
          <Select
            id="intake-dinner-select"
            name="dinner"
            options={intakeOptions}
            className="react-select-container--clinical w-56"
            classNamePrefix="react-select"
            placeholder="Select one..."
            value={intakeOptions.find(opt => opt.value === intake.dinner)}
            onChange={(selected, action) => {
              _handleSelectChange(selected.value, action.name)
            }}
          />
        </label>
        <label className="p-3 pb-6 pl-4 bg-gray-100 flex justify-between items-center">
          Snack 1
          <InputWithCharsLeft
            id="snack-one"
            name="snackOne"
            type="text"
            value={intake.snackOne || ""}
            onChange={_handleChange}
            className="px-4 py-3 w-56 outline-none rounded-lg border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200"
            maxLength={60}
            absolute="true"
          />
        </label>
        <label className="p-3 pb-6 pl-4 flex justify-between items-center">
          Snack 2
          <InputWithCharsLeft
            id="snack-two"
            name="snackTwo"
            type="text"
            value={intake.snackTwo || ""}
            onChange={_handleChange}
            className="px-4 py-3 w-56 outline-none rounded-lg border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200"
            maxLength={60}
            absolute="true"
          />
        </label>
        <label className="p-3 pb-6 pl-4 bg-gray-100 flex justify-between items-center">
          Snack 3
          <InputWithCharsLeft
            id="snack-three"
            name="snackThree"
            type="text"
            value={intake.snackThree || ""}
            onChange={_handleChange}
            className="px-4 py-3 w-56 outline-none rounded-lg border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200"
            maxLength={60}
            absolute="true"
          />
        </label>
        <label className="p-3 pb-3 pl-4 flex justify-between items-center">
          Liquid (mL)
          <input
            id="liquid"
            name="liquid"
            type="number"
            value={intake.liquid}
            onChange={_handleChange}
            className="px-4 py-3 w-32 rounded-lg text-center outline-none border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200 remove-input-steps"
          />
        </label>
        {intake.liquid> 10000 && <Error className="p-2 text-right">The Liquid value may be no greater than 10,000</Error>}
      </form>
    </div>
  )
}

export default Intake
