import { createSelector } from 'reselect';

const getHomeMakingTasksByShiftReportId = (state, props) => {
    return state.entities.shiftReports.byId[props.shiftReportId].homeMakingTasks.map((id) => state.entities.homeMakingTasks.byId[id]);
};

export const getHomeMakingTasks = createSelector(
    [getHomeMakingTasksByShiftReportId],
    (homeMakingTasks) => {
        return homeMakingTasks;
    }
);

export const getHomeMakingTaskMatrix = createSelector(
    [getHomeMakingTasks],
    (homeMakingTasks) => {
        const homeMakingTaskMatrix = [];
        homeMakingTasks.forEach((homeMakingTask) => {
            if (homeMakingTaskMatrix[homeMakingTask.type] === undefined) {
                homeMakingTaskMatrix[homeMakingTask.type] = [];
            }
            homeMakingTaskMatrix[homeMakingTask.type][
                homeMakingTask.recordedAt
            ] = homeMakingTask;
        });

        return homeMakingTaskMatrix;
    }
);
