import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import Settings from '../containers/Settings';
import Client from '../containers/Client';
import Reset from '../containers/Reset';
import Home from '../containers/Home';
import Logout from '../containers/Logout';
import ShiftReportWizard from '../containers/ShiftReportWizard';
import HhaShiftReportV1 from '../containers/shiftReports/hha/v1/ShiftReport';
import HhaShiftReportV1ReadOnly from '../containers/shiftReports/hha/v1/ShiftReportReadOnly';
import PcaShiftReportV1 from '../containers/shiftReports/pca/v1/ShiftReport';
import PcaShiftReportV1ReadOnly from '../containers/shiftReports/pca/v1/ShiftReportReadOnly';
import ClinicalShiftReportV1 from '../containers/shiftReports/clinical/v1/ShiftReport';
import BasicAssessment from '../components/shiftReports/clinical/v1/BasicAssessment';
import DigestiveAssessment from '../components/shiftReports/clinical/v1/DigestiveAssessment';
import CardiovascularAssessment from '../components/shiftReports/clinical/v1/CardiovascularAssessment';
import PulmonaryAssessment from '../components/shiftReports/clinical/v1/PulmonaryAssessment';
import connectAssessment from '../containers/shiftReports/clinical/v1/connectAssessment';
import NeuroAssessment from '../components/shiftReports/clinical/v1/neuroAssessment/neuroAssessment';

const AppRouter = () => (
    <Router>
        <Route path='/' exact component={Home} />
        <Route path='/reset' exact component={Reset} />
        <Route path='/settings' component={Settings} />
        <Route path='/client' exact component={Client} />
        <Route path='/client/:page' exact component={Client} />
        <Route path='/logout' exact component={Logout} />
        <Route path='/shift-report/new' component={ShiftReportWizard} />
        <Route
            path='/shift-report/hha/v1/:id/edit'
            component={HhaShiftReportV1}
        />
        <Route
            path='/shift-report/hha/v1/:id/view'
            component={HhaShiftReportV1ReadOnly}
        />
        <Route
            path='/shift-report/pca/v1/:id/edit'
            component={PcaShiftReportV1}
        />
        <Route
            path='/shift-report/pca/v1/:id/view'
            component={PcaShiftReportV1ReadOnly}
        />
        <Route
            path='/shift-report/clinical/v1/:id/edit'
            component={ClinicalShiftReportV1}
        />
        <Route
            path='/shift-report/clinical/v1/:id/assessment/basic'
            component={connectAssessment(BasicAssessment)}
        />
        <Route
            path='/shift-report/clinical/v1/:id/assessment/digestive'
            component={connectAssessment(DigestiveAssessment)}
        />
        <Route
            path='/shift-report/clinical/v1/:id/assessment/cardiovascular'
            component={connectAssessment(CardiovascularAssessment)}
        />
        <Route
            path='/shift-report/clinical/v1/:id/assessment/neuro'
            component={connectAssessment(NeuroAssessment)}
        />
        <Route
            path='/shift-report/clinical/v1/:id/assessment/pulmonary'
            component={connectAssessment(PulmonaryAssessment)}
        />
    </Router>
);

export default AppRouter;
