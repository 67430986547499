import {
    SHIFT_TYPE_HHA,
    SHIFT_TYPE_PCA,
    SHIFT_TYPE_CLINICAL,
    SHIFT_TYPE_RN,
} from '../constants';

export const shiftReportTypes = (type) => {
    switch (type) {
        case SHIFT_TYPE_HHA:
            return SHIFT_TYPE_HHA;
        case SHIFT_TYPE_PCA:
            return SHIFT_TYPE_PCA;
        case SHIFT_TYPE_RN:
            return SHIFT_TYPE_CLINICAL;
        default:
            return;
    }
};
