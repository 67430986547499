import { createSelector } from 'reselect';

const getPcaPersonalCareTasksByShiftReportId = (state, props) => {
    return state.entities.shiftReports.byId[
        props.shiftReportId
    ].pcaPersonalCareTasks.map(
        (id) => state.entities.pcaPersonalCareTasks.byId[id]
    );
};

export const getPcaPersonalCareTasks = createSelector(
    [getPcaPersonalCareTasksByShiftReportId],
    (pcaPersonalCareTasks) => {
        return pcaPersonalCareTasks;
    }
);

export const getPcaPersonalCareTaskMatrix = createSelector(
    [getPcaPersonalCareTasks],
    (pcaPersonalCareTasks) => {
        const pcaPersonalCareTaskMatrix = [];
        pcaPersonalCareTasks.forEach((pcaPersonalCareTask) => {
            if (
                pcaPersonalCareTaskMatrix[pcaPersonalCareTask.type] ===
                undefined
            ) {
                pcaPersonalCareTaskMatrix[pcaPersonalCareTask.type] = [];
            }
            pcaPersonalCareTaskMatrix[pcaPersonalCareTask.type][
                pcaPersonalCareTask.recordedAt
            ] = pcaPersonalCareTask;
        });

        return pcaPersonalCareTaskMatrix;
    }
);
