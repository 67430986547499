import React from 'react'
import PropTypes from 'prop-types'
import Error from '../../../form/Error'
import FormCheckbox from '../../../form/FormCheckbox'
import CheckboxWithInput from '../../../form/CheckboxWithInput'

const AssessmentCheckboxes = ({
  fields,
  onClick,
  values,
  onInputChange,
  category,
  title,
  errors,
  includeOther,
  assessmentLabels
}) => (
  <div className="my-6">
    <h4 id={`${category}-heading`} className="block text-gray-800 text-xl font-bold mb-6">{title} (Required)</h4>
    <div className="flex flex-wrap" id={`${category}-checkboxes`}>
      {fields.filter(x => !x.startsWith(`${category}_other`)).map((type, i) => (
        <FormCheckbox
          key={i}
          className="mr-4 p-2 rounded flex items-center"
          onClick={() => { onClick(`${category}.${type}`, !values[type])}}
          checked={!!values[type]}>
          {assessmentLabels[type]}
        </FormCheckbox>
      ))}
      {includeOther && (
        <CheckboxWithInput
          className="mr-4 p-2 rounded flex items-start mt-2"
          onClick={() => { 
            onClick(`${category}.${category}_other`, !values[`${category}_other`]);
            if(values[`${category}_other`]) {
              onClick(`${category}.${category}_other_value`, '')
            }
          }}
          checked={!!values[`${category}_other`]}
          onInputChange={onInputChange}
          checkboxTitle="Other"
          inputName={`${category}.${category}_other_value`}
          inputValue={values[`${category}_other_value`]}
          inputPlaceholder="Please clarify..."
        />
      )}
    </div>

    {errors[`${category}`] && <Error id={`basic-${category}-error`}>{typeof errors[`${category}`] === 'string' ? errors[`${category}`] : errors[`${category}`][`${category}_other_value`]}</Error>}
  </div>
);

AssessmentCheckboxes.propTypes = {
  fields: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  includeOther: PropTypes.bool,
  onInputChange: PropTypes.func,
  onClick: PropTypes.func.isRequired
}

AssessmentCheckboxes.defaultProps = {
  includeOther: false
}

export default AssessmentCheckboxes;
