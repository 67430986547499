import React, { useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { shiftReportTypes } from '../../../../utils/shiftTypes'
import {
  assessmentComplete,
  extractBasicAssessment,
  extractDigestiveAssessment,
  extractCardioAssessment,
  extractNeuroAssessment,
  extractPulmonaryAssessment
} from '../../../../utils/assessmentUtils'
import Header from '../../../Header'
import Container from '../../../layout/Container'
import Vitals from '../../../../containers/Vitals'
import ClinicalContinuationNotes from '../../../../containers/shiftReports/clinical/v1/ClinicalContinuationNotes'
import PrimaryButton from '../../../../components/form/PrimaryButton'
import ShiftReportHeader from '../../../ShiftReportHeader'
import AssessmentTableV2 from './AssessmentTableV2'
import Intake from '../../../../containers/shiftReports/clinical/v1/intake'
import Output from '../../../../containers/shiftReports/clinical/v1/output'
import WoundCares from '../../../../containers/shiftReports/clinical/v1/woundCares'
import PainAssessment from '../../../../containers/shiftReports/clinical/v1/painAssessment'

const ShiftReport = (props) => {
  const shiftReportPath = `/shift-report/${shiftReportTypes(props.shiftReport.type)}/v${props.shiftReport.version}/${props.shiftReport.id}`;

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  const assessments = useMemo(() => {
    return [
      {
        name: 'Basic',
        active: assessmentComplete(extractBasicAssessment(props.assessment)),
        url: `${shiftReportPath}/assessment/basic`
      },
      {
        name: 'Digestive',
        active: assessmentComplete(extractDigestiveAssessment(props.assessment)),
        url: `${shiftReportPath}/assessment/digestive`
      },
      {
        name: 'Neurological',
        active: assessmentComplete(extractNeuroAssessment(props.assessment)),
        url: `${shiftReportPath}/assessment/neuro`
      },
      {
        name: 'Cardiovascular',
        active: assessmentComplete(extractCardioAssessment(props.assessment)),
        url: `${shiftReportPath}/assessment/cardiovascular`
      },
      {
        name: 'Pulmonary',
        active: assessmentComplete(extractPulmonaryAssessment(props.assessment)),
        url: `${shiftReportPath}/assessment/pulmonary`
      }
    ]
  }, [props.assessment, shiftReportPath])

  return (
    <Container>
      <Header client={props.client} user={props.user} />
      <div className="p-8">
        <ShiftReportHeader
          shiftReport={props.shiftReport}
          author={props.user}
          onEditEndTime={props.onEditEndTime}
        >
          <Link to="/client" className="cursor-pointer bg-gray-400 p-3 px-6 text-gray-800 mr-2 inline-block font-semibold rounded-full">
            <i className="fas fa-save inline-block mr-2" /> Save and Exit
          </Link>
          <PrimaryButton id="report-submit-button" onClick={props.onSubmit}><i className="fas fa-file-upload mr-2 inline-block" /> Submit Shift Report</PrimaryButton>
        </ShiftReportHeader>
        <h3 id="assessment-header" className="block text-gray-600 text-3xl mb-6">Assessment</h3>
        <AssessmentTableV2
          assessments={assessments}
          intake={props.intake || undefined}
          output={props.output || undefined}
          vitalsCount={props.vitals.length}
          woundCaresCount={props.woundCares.length}
          painAssessmentsCount={props.painAssessments.length}
          clinicalContinuationNotes={props.clinicalContinuationNotes}
          shiftReport={props.shiftReport}
        />
        <div className="flex">
          <div className="block w-1/3 m-1 my-2 mr-4">
          <h3 id="intake-header" className="block text-gray-600 text-3xl my-6">Intake</h3>
            <Intake {...props} />
          </div>
          <div className="block w-1/3 h-full m-1 my-2 ml-4">
          <h3 id="output-header" className="block text-gray-600 text-3xl my-6">Output</h3>
            <Output {...props} />
          </div>
        </div>
        <h1 id="notes-header" className="text-2xl mb-4">Nursing Notes</h1>
        <div className="mb-8 flex flex-wrap">
          <ClinicalContinuationNotes
            shiftReport={props.shiftReport}
            clinicalContinuationNotes={props.clinicalContinuationNotes}
          />
        </div>
        <h1 id="vitals-header" className="text-2xl mb-4">Vitals</h1>
        <div className="mb-8 flex flex-wrap">
          <Vitals shiftReport={props.shiftReport} />
        </div>
        <h1 id="wound-cares-header" className="text-2xl mb-4">Wound Care</h1>
        <div className="mb-8 flex flex-wrap">
          <WoundCares shiftReport={props.shiftReport} />
        </div>
        <h1 id="pain-assessments-header" className="text-2xl mb-4">Pain Assessment</h1>
        <div className="mb-8 flex flex-wrap">
          <PainAssessment shiftReport={props.shiftReport} />
        </div>

      </div>
    </Container>
  )
}

export default ShiftReport;
