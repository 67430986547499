import { call, put, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr';
import humps from 'humps';
import {
  POST_SHIFT_REPORT_REQUESTED,
  POST_SHIFT_REPORT_SUCCEEDED,
  POST_SHIFT_REPORT_FAILED,
  FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED,
  FETCH_CLIENT_SHIFT_REPORTS_FAILED,
  FETCH_CLIENT_SHIFT_REPORTS_REQUESTED
} from '../actions';
import Api from '../services/Api';
import { SHIFT_REPORT_STATUS_FAILED_NETWORK_CONNECTIVITY, SHIFT_REPORT_STATUS_SUBMITTED, SHIFT_REPORT_STATUS_FAILED } from "../constants";
import shiftReportSchema from '../schemas/shiftReport';

function* fetchShiftReportsByClientId(action) {
  try {
    const response = yield call(Api.fetchShiftReportsByClientId, action.payload.clientId);

    const json = {
      shiftReports: humps.camelizeKeys(yield response.json())
    };

    const normalized = normalize(json, {
      shiftReports: [shiftReportSchema]
    });

    if(response.status === 200) {
      yield put({
        type: FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED,
        payload: {
          personalCareTasks: {
            byId: normalized.entities.personalCareTasks || {},
          },
          homeMakingTasks: {
            byId: normalized.entities.homeMakingTasks || {},
          },
          activities: {
            byId: normalized.entities.activities || {},
          },
          incidentReports: {
            byId: normalized.entities.incidentReports || {},
          },
          shiftReports: {
            byId: normalized.entities.shiftReports || {},
          },
          vitals: {
            byId: normalized.entities.vitals || {},
          },
          clientId: action.payload.clientId,
          lastUpdated: Date.now(),
        }
      });
    } else {
      yield put({
        type: FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED,
        payload: {
          clientId: action.payload.clientId,
          lastUpdated: Date.now()
        }
      });
    }
  }
  catch(e) {
    yield put({
      type: FETCH_CLIENT_SHIFT_REPORTS_FAILED,
      payload: {
        clientId: action.payload.clientId,
        lastUpdated: Date.now()
      }
    });
  }
}

function* postShiftReport(action) { try {
  const response = yield call(Api.postShiftReport, action.payload.serialized);
  if(response.status === 201) {
    yield put({
      type: POST_SHIFT_REPORT_SUCCEEDED,
      payload: {
        id: action.payload.id,
        status: SHIFT_REPORT_STATUS_SUBMITTED,
        lastUpdated: Date.now(),
      }
    });
  } else {
    yield put({
      type: POST_SHIFT_REPORT_SUCCEEDED,
      payload: {
        id: action.payload.id,
        status: SHIFT_REPORT_STATUS_FAILED,
        lastUpdated: Date.now(),
      }
    });
  }
} catch (e) {
  yield put({
    type: POST_SHIFT_REPORT_FAILED,
    payload: {
      id: action.payload.id,
      status: SHIFT_REPORT_STATUS_FAILED_NETWORK_CONNECTIVITY,
      lastUpdated: Date.now(),
    },
  });
}
}

function* shiftReportSaga() {
  yield takeLatest(POST_SHIFT_REPORT_REQUESTED, postShiftReport);
  yield takeLatest(FETCH_CLIENT_SHIFT_REPORTS_REQUESTED, fetchShiftReportsByClientId);
}

export default shiftReportSaga;