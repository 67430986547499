import React from 'react';
import { connect } from 'react-redux';
import { getClient, getUser } from '../../../../selectors';
import ReadOnlyComponent from '../../../../components/shiftReports/pca/v1/ShiftReportReadOnly';
import { getPcaPersonalCareTaskMatrix } from '../../../../selectors/pcaPersonalCareTask';
import { generateTimeRange } from '../../../../utils/timeUtils';
import { getHomeMakingTaskMatrix } from '../../../../selectors/homeMakingTask';

class ShiftReportReadOnly extends React.Component {
  componentDidUpdate = prevProps => {
    if(prevProps.shiftReport.status !== this.props.shiftReport.status) {
      this.props.history.push('/client');
    }
  };

  render = () => <ReadOnlyComponent
                    {...this.props}
                    onSubmit={null}
                    times={generateTimeRange(this.props.shiftReport.start, this.props.shiftReport.end)}
                 />
}

const mapStateToProps = (state, props) => {
  const shiftReport = state.entities.shiftReports.byId[props.match.params.id]

  return {
      user: getUser(state),
      client: getClient(state),
      shiftReport: shiftReport,
      shiftReportUser: state.entities.employees.byId[shiftReport.userId],
      incidentReports: shiftReport.incidentReports.map(
          (id) => state.entities.incidentReports.byId[id]
      ),
      pcaPersonalCareTasks: getPcaPersonalCareTaskMatrix(state, {
          shiftReportId: props.match.params.id,
      }),
      homeMakingTasks: getHomeMakingTaskMatrix(state, {
          shiftReportId: props.match.params.id,
      }),
  };
}

const Container = connect(
  mapStateToProps,
  {},
)(ShiftReportReadOnly);

export default Container;
