import React, { useState, useEffect } from 'react'

const Output = (props) => {
  const [output, setOutput] = useState({
    urine: props.output.urine || 0,
    emesis: props.output.emesis || 0,
    bowelMovement: props.output.bowelMovement || 0,
  })

  const { updateOutput } = props
  const outputId = props.output.id

  useEffect(() => {
    updateOutput({
      id: outputId,
      ...output
    })
  }, [output, outputId, updateOutput])


  const _handleChange = (e) => {
    let { name, value } = e.target;
    if(value < 0){
      value = 0
    }
    setOutput({...output, [name]: value})
  }

  const _handleStepUp = (e) =>{
    const {name, value} = e.target.previousSibling
    setOutput({...output, [name]: parseInt(value) + 1})
  }

  const _handleStepDown = (e) =>{
    const {name, value} = e.target.nextSibling
    if(output[name] > 0){
      setOutput({...output, [name]: parseInt(value) - 1})
    }
  }

  return (
    <div className="bg-white rounded-lg shadow-md">
      <form>
        <label className="p-3 pb-3 pl-4 flex justify-between items-center">
          <div>
            Urine
            <div className="text-sm text-gray-600">Number of times</div>
          </div>
          <div className="px-2 rounded-lg bg-gray-300 flex justify-around items-center">
            <i className="fas fa-minus p-2 pr-3 text-2xl" onClick={_handleStepDown}/>
            <input
              id="urine"
              name="urine"
              type="number"
              value={output.urine}
              onChange={_handleChange}
              readOnly={true}
              className="px-4 py-3 w-32 text-center outline-none border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200 remove-input-steps"
            />
            <i className="fas fa-plus p-2 pl-3 text-2xl" onClick={_handleStepUp}/>
          </div>
        </label>
        <label className="p-3 pb-3 pl-4 bg-gray-100 flex justify-between items-center">
          <div>
            Emesis
            <div className="text-sm text-gray-600">Number of times</div>
          </div>
          <div className="px-2 rounded-lg bg-gray-300 flex justify-around items-center">
            <i className="fas fa-minus p-2 pr-3 text-2xl" onClick={_handleStepDown}/>
            <input
              id="emesis"
              name="emesis"
              type="number"
              value={output.emesis}
              onChange={_handleChange}
              readOnly={true}
              className="px-4 py-3 w-32 text-center outline-none border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200 remove-input-steps"
            />
            <i className="fas fa-plus p-2 pl-3 text-2xl" onClick={_handleStepUp}/>
          </div>
        </label>
        <label className="p-3 pb-3 pl-4 flex justify-between items-center">
          <div>
            Bowel Movement
            <div className="text-sm text-gray-600">Number of times</div>
          </div>
          <div className="px-2 rounded-lg bg-gray-300 flex justify-around items-center">
            <i className="fas fa-minus p-2 pr-3 text-2xl" onClick={_handleStepDown}/>
            <input
              id="bowel-movement"
              name="bowelMovement"
              type="number"
              value={output.bowelMovement}
              onChange={_handleChange}
              readOnly={true}
              className="px-4 py-3 w-32 text-center outline-none border-2 border-gray-200 focus:bg-blue-100 focus:border-blue-200 remove-input-steps"
            />
            <i className="fas fa-plus p-2 pl-3 text-2xl" onClick={_handleStepUp}/>
          </div>
        </label>
      </form>
    </div>
  )
}

export default Output
