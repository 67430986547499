import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import without from 'lodash/without';
import { combineReducers } from 'redux';
import verificationImagesReducer from './verificationImages';
import {
    ACTIVITY_CREATED,
    ACTIVITY_DELETED,
    ACTIVITY_UPDATED,
    CLINICAL_CONTINUATION_NOTE_CREATED,
    CLINICAL_CONTINUATION_NOTE_DELETED,
    CLINICAL_CONTINUATION_NOTE_UPDATED,
    PERSONAL_CARE_TASK_CREATED,
    PERSONAL_CARE_TASK_UPDATED,
    PERSONAL_CARE_TASK_DELETED,
    PCA_PERSONAL_CARE_TASK_CREATED,
    PCA_PERSONAL_CARE_TASK_UPDATED,
    PCA_PERSONAL_CARE_TASK_DELETED,
    HOME_MAKING_TASK_CREATED,
    HOME_MAKING_TASK_UPDATED,
    HOME_MAKING_TASK_DELETED,
    CLIENT_FETCH_SUCCEEDED,
    SHIFT_REPORT_CREATED,
    VITALS_CREATED,
    VITALS_UPDATED,
    POST_SHIFT_REPORT_REQUESTED,
    SHIFT_REPORT_DELETED,
    SHIFT_REPORT_IMAGE_CAPTURED,
    INCIDENT_REPORT_CREATED,
    INCIDENT_REPORT_UPDATED,
    INCIDENT_REPORT_DELETED,
    POST_SHIFT_REPORT_SUCCEEDED,
    POST_SHIFT_REPORT_FAILED,
    SHIFT_REPORT_UPDATED,
    FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED,
    APPLICATION_DATA_RESET,
    ASSESSMENT_CREATED,
    ASSESSMENT_UPDATED,
    ASSESSMENT_DELETED,
    INTAKE_CREATED,
    INTAKE_UPDATED,
    INTAKE_DELETED,
    OUTPUT_CREATED,
    OUTPUT_UPDATED,
    OUTPUT_DELETED,
    WOUND_CARE_CREATED,
    WOUND_CARE_UPDATED,
    PAIN_ASSESSMENT_CREATED,
    PAIN_ASSESSMENT_UPDATED,
} from '../actions';

const activitiesReducer = (state = { byId: {}, allIds: []}, action) => {
  switch(action.type) {
    case APPLICATION_DATA_RESET:
      return {
        byId: {},
        allIds: [],
      }
    case SHIFT_REPORT_DELETED:
      return Object.assign({}, {
        byId: pick(state.byId, state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id)),
        allIds: state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id),
      });
    case ACTIVITY_DELETED:
      return merge({}, {
        byId: omit(state.byId, [action.payload.activity.id]),
        allIds: without(state.allIds, action.payload.activity.id),
      });
    case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
      return merge({}, state, {
        byId: action.payload.activities.byId,
        allIds: union(state.allIds, Object.keys(action.payload.activities.byId)),
      });
    case ACTIVITY_CREATED:
      return merge({}, state, {
        byId: {
          [action.payload.id]: {
            ...action.payload,
            deletedAt: null,
          }
        },
        allIds: union(state.allIds, [action.payload.id]),
      });
    case ACTIVITY_UPDATED:
      return merge({}, state, {
        byId: {
          [action.payload.id]: action.payload
        }
      });
    default:
      return state;
  }
}

const personalCareTasksReducer = (state = { byId: {}, allIds: [] }, action) => {
 switch(action.type) {
   case APPLICATION_DATA_RESET:
     return {
       byId: {},
       allIds: [],
     }
   case SHIFT_REPORT_DELETED:
     return Object.assign({}, {
       byId: pick(state.byId, state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id)),
       allIds: state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id),
     });
   case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
     return merge({}, state, {
       byId: action.payload.personalCareTasks.byId,
       allIds: union(state.allIds, Object.keys(action.payload.personalCareTasks.byId)),
     });
   case PERSONAL_CARE_TASK_CREATED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: {
           ...action.payload,
           deletedAt: null,
         }
       },
       allIds: union(state.allIds, [action.payload.id]),
     });
   case PERSONAL_CARE_TASK_UPDATED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: action.payload
       }
     });
   case PERSONAL_CARE_TASK_DELETED:
     return merge({}, {
       byId: omit(state.byId, [action.payload.personalCareTask.id]),
       allIds: without(state.allIds, action.payload.personalCareTask.id),
     });
   default:
     return state;
 }
}

const pcaPersonalCareTasksReducer = (state = { byId: {}, allIds: [] }, action) => {
    switch (action.type) {
        case APPLICATION_DATA_RESET:
            return {
                byId: {},
                allIds: [],
            };
        case SHIFT_REPORT_DELETED:
            return Object.assign(
                {},
                {
                    byId: pick(
                        state.byId,
                        state.allIds.filter(
                            (id) =>
                                state.byId[id].shiftReportId !==
                                action.payload.id
                        )
                    ),
                    allIds: state.allIds.filter(
                        (id) =>
                            state.byId[id].shiftReportId !== action.payload.id
                    ),
                }
            );
        case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
            return merge({}, state, {
                byId: action.payload.pcaPersonalCareTasks.byId,
                allIds: union(
                    state.allIds,
                    Object.keys(action.payload.pcaPersonalCareTasks.byId)
                ),
            });
        case PCA_PERSONAL_CARE_TASK_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.id]: {
                        ...action.payload,
                        deletedAt: null,
                    },
                },
                allIds: union(state.allIds, [action.payload.id]),
            });
        case PCA_PERSONAL_CARE_TASK_UPDATED:
            return merge({}, state, {
                byId: {
                    [action.payload.id]: action.payload,
                },
            });
        case PCA_PERSONAL_CARE_TASK_DELETED:
            return merge({},{
                    byId: omit(state.byId, [
                        action.payload.pcaPersonalCareTask.id,
                    ]),
                    allIds: without(
                        state.allIds,
                        action.payload.pcaPersonalCareTask.id
                    ),
                }
            );
        default:
            return state;
    }
};

const homeMakingTasksReducer = (
    state = { byId: {}, allIds: [] },
    action
) => {
    switch (action.type) {
        case APPLICATION_DATA_RESET:
            return {
                byId: {},
                allIds: [],
            };
        case SHIFT_REPORT_DELETED:
            return Object.assign(
                {},
                {
                    byId: pick(
                        state.byId,
                        state.allIds.filter(
                            (id) =>
                                state.byId[id].shiftReportId !==
                                action.payload.id
                        )
                    ),
                    allIds: state.allIds.filter(
                        (id) =>
                            state.byId[id].shiftReportId !== action.payload.id
                    ),
                }
            );
        case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
            return merge({}, state, {
                byId: action.payload.homeMakingTasks.byId,
                allIds: union(
                    state.allIds,
                    Object.keys(action.payload.homeMakingTasks.byId)
                ),
            });
        case HOME_MAKING_TASK_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.id]: {
                        ...action.payload,
                        deletedAt: null,
                    },
                },
                allIds: union(state.allIds, [action.payload.id]),
            });
        case HOME_MAKING_TASK_UPDATED:
            return merge({}, state, {
                byId: {
                    [action.payload.id]: action.payload,
                },
            });
        case HOME_MAKING_TASK_DELETED:
            return merge(
                {},
                {
                    byId: omit(state.byId, [
                        action.payload.homeMakingTask.id,
                    ]),
                    allIds: without(
                        state.allIds,
                        action.payload.homeMakingTask.id
                    ),
                }
            );
        default:
            return state;
    }
};

const clinicalContinuationNotesReducer = (state = { byId: {}, allIds: []}, action) => {
  switch(action.type) {
    case APPLICATION_DATA_RESET:
      return {
        byId: {},
        allIds: [],
      }
    case SHIFT_REPORT_DELETED:
      return Object.assign({}, {
        byId: pick(state.byId, state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id)),
        allIds: state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id),
      });
    case CLINICAL_CONTINUATION_NOTE_DELETED:
      return merge({}, {
        byId: omit(state.byId, [action.payload.clinicalContinuationNote.id]),
        allIds: without(state.allIds, action.payload.clinicalContinuationNote.id),
      });
    case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
      return merge({}, state, {
        byId: action.payload.clinicalContinuationNotes.byId,
        allIds: union(state.allIds, Object.keys(action.payload.clinicalContinuationNotes.byId)),
      });
    case CLINICAL_CONTINUATION_NOTE_CREATED:
      return merge({}, state, {
        byId: {
          [action.payload.id]: {
            ...action.payload,
            deletedAt: null,
          }
        },
        allIds: union(state.allIds, [action.payload.id]),
      });
    case CLINICAL_CONTINUATION_NOTE_UPDATED:
      return merge({}, state, {
        byId: {
          [action.payload.id]: action.payload
        }
      });
    default:
      return state;
  }
}

const incidentReportsReducer = (state = { byId: {}, allIds: []}, action) => {
 switch(action.type) {
   case APPLICATION_DATA_RESET:
     return {
       byId: {},
       allIds: [],
     }
   case SHIFT_REPORT_DELETED:
     return Object.assign({}, {
       byId: pick(state.byId, state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id)),
       allIds: state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id),
     });
   case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
     return merge({}, state, {
       byId: action.payload.incidentReports.byId,
       allIds: union(state.allIds, Object.keys(action.payload.incidentReports.byId)),
     });
   case INCIDENT_REPORT_CREATED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: {
           ...action.payload,
           deletedAt: null,
         }
       },
       allIds: union(state.allIds, [action.payload.id]),
     });
   case INCIDENT_REPORT_UPDATED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: action.payload
       }
     });
   case INCIDENT_REPORT_DELETED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: action.payload
       }
     });
   default:
     return state;
 }
}

const assessmentsReducer = (state = { byId: {}, allIds: []}, action) => {
 switch(action.type) {
   case APPLICATION_DATA_RESET:
     return {
       byId: {},
       allIds: [],
     }
   case SHIFT_REPORT_DELETED:
     return Object.assign({}, {
       byId: pick(state.byId, state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id)),
       allIds: state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id),
     });
   case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
     return merge({}, state, {
       byId: action.payload.assessments.byId,
       allIds: union(state.allIds, Object.keys(action.payload.assessments.byId)),
     });
   case ASSESSMENT_CREATED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: {
           ...action.payload,
           deletedAt: null,
         }
       },
       allIds: union(state.allIds, [action.payload.id]),
     });
   case ASSESSMENT_UPDATED:
     return mergeWith({}, state, {
       byId: {
         [action.payload.id]: action.payload
       }
     }, (oldValue, newValue) => {
       if(Array.isArray(oldValue) && Array.isArray(newValue) && newValue.length === 0) {
         return newValue
       }
     });
   case ASSESSMENT_DELETED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: action.payload
       }
     });
   default:
     return state;
 }
}

const intakesReducer = (state = { byId: {}, allIds: []}, action) => {
 switch(action.type) {
   case APPLICATION_DATA_RESET:
     return {
       byId: {},
       allIds: [],
     }
   case SHIFT_REPORT_DELETED:
     return Object.assign({}, {
       byId: pick(state.byId, state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id)),
       allIds: state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id),
     });
   case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
     return merge({}, state, {
       byId: action.payload.intakes.byId,
       allIds: union(state.allIds, Object.keys(action.payload.intakes.byId)),
     });
   case INTAKE_CREATED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: {
           ...action.payload,
           deletedAt: null,
         }
       },
       allIds: union(state.allIds, [action.payload.id]),
     });
   case INTAKE_UPDATED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: action.payload
       }
     });
   case INTAKE_DELETED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: action.payload
       }
     });
   default:
     return state;
 }
}

const outputsReducer = (state = { byId: {}, allIds: []}, action) => {
 switch(action.type) {
   case APPLICATION_DATA_RESET:
     return {
       byId: {},
       allIds: [],
     }
   case SHIFT_REPORT_DELETED:
     return Object.assign({}, {
       byId: pick(state.byId, state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id)),
       allIds: state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id),
     });
   case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
     return merge({}, state, {
       byId: action.payload.outputs.byId,
       allIds: union(state.allIds, Object.keys(action.payload.outputs.byId)),
     });
   case OUTPUT_CREATED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: {
           ...action.payload,
           deletedAt: null,
         }
       },
       allIds: union(state.allIds, [action.payload.id]),
     });
   case OUTPUT_UPDATED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: action.payload
       }
     });
   case OUTPUT_DELETED:
     return merge({}, state, {
       byId: {
         [action.payload.id]: action.payload
       }
     });
   default:
     return state;
 }
}

const clientsReducer = (state = { byId: {}, allIds: [] }, action) => {
 switch(action.type) {
   case APPLICATION_DATA_RESET:
     return {
       byId: {},
       allIds: [],
     }
   case SHIFT_REPORT_DELETED:
     return Object.assign({}, state, {
       byId: {
         ...state.byId,
         [action.payload.clientId]: {
           ...state.byId[action.payload.clientId],
           shiftReports: state.byId[action.payload.clientId].shiftReports.filter(id => id !== action.payload.id),
         }
       },
     });
   case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
     return merge({}, state, {
       byId: {
         [action.payload.clientId]: {
           shiftReports: union(state.byId[action.payload.clientId].shiftReports, Object.keys(action.payload.shiftReports.byId)),
         }
       }
     });
   case SHIFT_REPORT_CREATED:
     return merge({}, state, {
       byId: {
         [action.payload.clientId]: {
           shiftReports: union(state.byId[action.payload.clientId].shiftReports, [action.payload.id])
         }
       }
     });
   case CLIENT_FETCH_SUCCEEDED:
     return merge({}, state, {
       byId: action.payload.clients.byId,
       allIds: union(state.allIds, Object.keys(action.payload.clients.byId).map(Number)),
     });
   default:
     return state;
 }
};

const employeesReducer = (state = { byId: {}, allIds: [] }, action) => {
  switch(action.type) {
    case APPLICATION_DATA_RESET:
      return {
        byId: {},
        allIds: [],
      }
    case SHIFT_REPORT_CREATED:
      return merge({}, state, {
        byId: {
          [action.payload.userId]: {
            shiftReports: union(state.byId[action.payload.userId].shiftReports, [action.payload.id])
          }
        }
      });
    case CLIENT_FETCH_SUCCEEDED:
      return merge({}, state, {
        byId: action.payload.employees.byId,
        allIds: union(state.allIds, Object.keys(action.payload.employees.byId).map(Number)),
      });
    default:
      return state;
  }
};

const vitalsReducer = (state = { byId: {}, allIds: [] }, action)  => {
  switch(action.type) {
    case APPLICATION_DATA_RESET:
      return {
        byId: {},
        allIds: [],
      }
    case SHIFT_REPORT_DELETED:
      return Object.assign({}, {
        byId: pick(state.byId, state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id)),
        allIds: state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id),
      });
    case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
      return merge({}, state, {
        byId: action.payload.vitals.byId,
        allIds: union(state.allIds, Object.keys(action.payload.vitals.byId)),
      });
    case VITALS_UPDATED:
      return merge({}, state, {
        byId: {
          [action.payload.id]: {
            ...action.payload,
          }
        }
      });
    case VITALS_CREATED:
      return merge({}, state, {
        byId: {
          [action.payload.id]: {
            ...action.payload
          },
        },
        allIds: union(state.allIds, [action.payload.id]),
      });
    default:
      return state;
  }
};
const woundCareReducer = (state = { byId: {}, allIds: [] }, action)  => {
  switch(action.type) {
    case APPLICATION_DATA_RESET:
      return {
        byId: {},
        allIds: [],
      }
    case SHIFT_REPORT_DELETED:
      return Object.assign({}, {
        byId: pick(state.byId, state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id)),
        allIds: state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id),
      });
    case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
      return merge({}, state, {
        byId: action.payload.woundCares.byId,
        allIds: union(state.allIds, Object.keys(action.payload.woundCares.byId)),
      });
    case WOUND_CARE_UPDATED:
      return merge({}, state, {
        byId: {
          [action.payload.id]: {
            ...action.payload,
          }
        }
      });
    case WOUND_CARE_CREATED:
      return merge({}, state, {
        byId: {
          [action.payload.id]: {
            ...action.payload
          },
        },
        allIds: union(state.allIds, [action.payload.id]),
      });
    default:
      return state;
  }
};
const painAssessmentReducer = (state = { byId: {}, allIds: [] }, action)  => {
  switch(action.type) {
    case APPLICATION_DATA_RESET:
      return {
        byId: {},
        allIds: [],
      }
    case SHIFT_REPORT_DELETED:
      return Object.assign({}, {
        byId: pick(state.byId, state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id)),
        allIds: state.allIds.filter(id => state.byId[id].shiftReportId !== action.payload.id),
      });
    case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
      return merge({}, state, {
        byId: action.payload.painAssessments.byId,
        allIds: union(state.allIds, Object.keys(action.payload.painAssessments.byId)),
      });
    case PAIN_ASSESSMENT_UPDATED:
      return merge({}, state, {
        byId: {
          [action.payload.id]: {
            ...action.payload,
          }
        }
      });
    case PAIN_ASSESSMENT_CREATED:
      return merge({}, state, {
        byId: {
          [action.payload.id]: {
            ...action.payload
          },
        },
        allIds: union(state.allIds, [action.payload.id]),
      });
    default:
      return state;
  }
};

const shiftReportReducer = (state = { byId: {}, allIds: [] }, action) => {
    switch (action.type) {
        case APPLICATION_DATA_RESET:
            return {
                byId: {},
                allIds: [],
            };
        case PERSONAL_CARE_TASK_DELETED:
            return Object.assign({}, state, {
                byId: {
                    ...state.byId,
                    [action.payload.personalCareTask.shiftReportId]: {
                        ...state.byId[
                            action.payload.personalCareTask.shiftReportId
                        ],
                        personalCareTasks: union(
                            [],
                            without(
                                state.byId[
                                    action.payload.personalCareTask
                                        .shiftReportId
                                ].personalCareTasks,
                                action.payload.personalCareTask.id
                            )
                        ),
                    },
                },
            });
        case ACTIVITY_DELETED:
            return Object.assign({}, state, {
                byId: {
                    ...state.byId,
                    [action.payload.activity.shiftReportId]: {
                        ...state.byId[action.payload.activity.shiftReportId],
                        activities: union(
                            [],
                            without(
                                state.byId[
                                    action.payload.activity.shiftReportId
                                ].activities,
                                action.payload.activity.id
                            )
                        ),
                    },
                },
            });
        case CLINICAL_CONTINUATION_NOTE_DELETED:
            return Object.assign({}, state, {
                byId: {
                    ...state.byId,
                    [action.payload.clinicalContinuationNote.shiftReportId]: {
                        ...state.byId[
                            action.payload.clinicalContinuationNote
                                .shiftReportId
                        ],
                        clinicalContinuationNotes: union(
                            [],
                            without(
                                state.byId[
                                    action.payload.clinicalContinuationNote
                                        .shiftReportId
                                ].clinicalContinuationNotes,
                                action.payload.clinicalContinuationNote.id
                            )
                        ),
                    },
                },
            });

        case FETCH_CLIENT_SHIFT_REPORTS_SUCCEEDED:
            return merge({}, state, {
                byId: action.payload.shiftReports.byId,
                allIds: union(
                    state.allIds,
                    Object.keys(action.payload.shiftReports.byId)
                ),
            });
        case ACTIVITY_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        activities: union(
                            state.byId[action.payload.shiftReportId].activities,
                            [action.payload.id]
                        ),
                    },
                },
            });
        case PERSONAL_CARE_TASK_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        personalCareTasks: union(
                            state.byId[action.payload.shiftReportId]
                                .personalCareTasks,
                            [action.payload.id]
                        ),
                    },
                },
            });
        case PCA_PERSONAL_CARE_TASK_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        pcaPersonalCareTasks: union(
                            state.byId[action.payload.shiftReportId]
                                .pcaPersonalCareTasks,
                            [action.payload.id]
                        ),
                    },
                },
            });
        case HOME_MAKING_TASK_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        homeMakingTasks: union(
                            state.byId[action.payload.shiftReportId]
                                .homeMakingTasks,
                            [action.payload.id]
                        ),
                    },
                },
            });
        case CLINICAL_CONTINUATION_NOTE_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        clinicalContinuationNotes: union(
                            state.byId[action.payload.shiftReportId]
                                .clinicalContinuationNotes,
                            [action.payload.id]
                        ),
                    },
                },
            });

        case INCIDENT_REPORT_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        incidentReports: union(
                            state.byId[action.payload.shiftReportId]
                                .incidentReports,
                            [action.payload.id]
                        ),
                    },
                },
            });
        case ASSESSMENT_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        assessment: action.payload.id,
                    },
                },
            });
        case INTAKE_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        intake: action.payload.id,
                    },
                },
            });
        case OUTPUT_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        output: action.payload.id,
                    },
                },
            });
        case SHIFT_REPORT_DELETED:
            return Object.assign(
                {},
                {
                    byId: pick(
                        state.byId,
                        state.allIds.filter((id) => id !== action.payload.id)
                    ),
                    allIds: state.allIds.filter(
                        (id) => id !== action.payload.id
                    ),
                }
            );
        case SHIFT_REPORT_UPDATED:
            return merge({}, state, {
                byId: {
                    [action.payload.id]: action.payload,
                },
            });
        case POST_SHIFT_REPORT_FAILED:
        case POST_SHIFT_REPORT_SUCCEEDED:
        case POST_SHIFT_REPORT_REQUESTED:
            return merge({}, state, {
                byId: {
                    [action.payload.id]: {
                        status: action.payload.status,
                        lastUpdated: action.payload.lastUpdated,
                    },
                },
            });
        case VITALS_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        vitals: union(
                            state.byId[action.payload.shiftReportId].vitals,
                            [action.payload.id]
                        ),
                    },
                },
            });
        case WOUND_CARE_CREATED: {
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        woundCares: union(
                            state.byId[action.payload.shiftReportId].woundCares,
                            [action.payload.id]
                        ),
                    },
                },
            });
        }
        case PAIN_ASSESSMENT_CREATED: {
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        painAssessments: union(
                            state.byId[action.payload.shiftReportId]
                                .painAssessments,
                            [action.payload.id]
                        ),
                    },
                },
            });
        }
        case SHIFT_REPORT_IMAGE_CAPTURED:
            return merge({}, state, {
                byId: {
                    [action.payload.shiftReportId]: {
                        verificationImage: action.payload.id,
                        lastUpdated: action.payload.lastUpdated,
                    },
                },
            });
        case SHIFT_REPORT_CREATED:
            return merge({}, state, {
                byId: {
                    [action.payload.id]: action.payload,
                },
                allIds: union(state.allIds, [action.payload.id]),
            });
        case PERSONAL_CARE_TASK_UPDATED:
            return merge({}, state, {
                byId: {
                    [action.payload.id]: {
                        updatedAt: action.payload.lastUpdated,
                        personalCareTasks: {
                            [action.payload.task]: {
                                [action.payload.time]: {
                                    status: action.payload.status,
                                    updatedAt: action.payload.lastUpdated,
                                },
                            },
                        },
                    },
                },
            });
        case ACTIVITY_UPDATED:
            return merge({}, state, {
                byId: {
                    [action.payload.id]: {
                        updatedAt: action.payload.lastUpdated,
                        activities: {
                            [action.payload.activity]: {
                                [action.payload.time]: {
                                    status: action.payload.status,
                                    updatedAt: action.payload.lastUpdated,
                                },
                            },
                        },
                    },
                },
            });
        default:
            return state;
    }
};

const entities = combineReducers({
  assessments: assessmentsReducer,
  intakes: intakesReducer,
  outputs: outputsReducer,
  woundCares: woundCareReducer,
  painAssessments: painAssessmentReducer,
  clients: clientsReducer,
  employees: employeesReducer,
  shiftReports: shiftReportReducer,
  vitals: vitalsReducer,
  personalCareTasks: personalCareTasksReducer,
  pcaPersonalCareTasks: pcaPersonalCareTasksReducer,
  homeMakingTasks: homeMakingTasksReducer,
  activities: activitiesReducer,
  clinicalContinuationNotes: clinicalContinuationNotesReducer,
  incidentReports: incidentReportsReducer,
  verificationImages: verificationImagesReducer
});

export default entities;
